import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProviderRegistrationService {

  headers;
  options;
  token;
  @Output() loginAuth = new EventEmitter();

  constructor(private http: HttpClient, private service: ApiRootService) { }

  registation(formData,callback) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.service.getRegistrationUrl()+'/userRegistration',formData, { headers: this.headers}).
            subscribe(
              (data)=>{
                callback(data);
              }
            );
  }

  getAllOrganization(callback) {
    return this.http.get(this.service.getRegistrationUrl()+'/getAllOrganizations').subscribe(
      (data)=> {
        callback(data);
      }
    )
  }
  authenticateUser(formData,callback) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'platform': 'web' });
    return this.http.post(this.service.getBaseUrl()+'/auth',formData, { headers: this.headers, observe: 'response'}).subscribe(
      (data) => {
        callback({responsecode:200,token:data.headers.get('authorization')});

      },
      (error)=>{
        callback({responsecode:401,message:"User Name or Password Incorrect"})
      }
    )
  }

  checkEmailAvialablity(email,callback){
    // const email_new = encodeURIComponent(email);
    return this.http.get(this.service.getRegistrationUrl()+'/getEmailAvailability/'+email).subscribe((data)=>{
      callback(data);
    })
  }

  checkUserNameAvialablity(username,callback){
    // const email_new = encodeURIComponent(email);
    return this.http.get(this.service.getRegistrationUrl()+'/checkUserNameavailability/'+username).subscribe((data)=>{
      callback(data);
    })
  }

  checkOrgNameAvialablity(orgname,callback){
    // const email_new = encodeURIComponent(email);
    return this.http.get(this.service.getRegistrationUrl()+'/getOrganizationNameAvailability/'+orgname).subscribe((data)=>{
      callback(data);
    })
  }

  getAllIndustry(callback) {
    return this.http.get(this.service.getRegistrationUrl()+'/getAllIndustryMaster').subscribe(
      (data) => {
        callback(data);
      }
    )

  }

  getWorkerType(username,callback) {
    return this.http.get(this.service.getRegistrationUrl()+'/workerType/'+username).subscribe(
      (data) => {
        //////console.log(data);
        callback(data);
      }
    )
  }
}
