import { Component, Input, Output, TemplateRef, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from '../new-job-card/new-job-card.service';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { missed_clock_in_out_inputs, scheduleStaff_Res } from 'src/app/shared/interface/core.interface';
import * as moment from 'moment';
import { Observable, interval, map } from 'rxjs';
import { CheckOutMissedService } from '../check-out-missed-list/check-out-missed-list.service';
 
import { ClockedOutPopUp } from '../clocked-out-pop-up/clocked-out-pop-up.component';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { ICustomerViewLimitations } from 'src/app/shared/interface/ICustomerViewLimits';
 
@Component({
  selector: 'admin-scheduled-staff-card',
  templateUrl: './scheduled-staff-card.component.html',
  styleUrls: ['./scheduled-staff-card.component.scss']
})
export class ScheduledStaffCardComponent {

 //  @Input() scheduleStaff_CardData: any[] = [] ;
   protected scheduleStaff_CardData : any[] = [] ;
  @Input() showMoreControls:boolean = true;
  @Output() emitter_refreshList = new EventEmitter()
  @ViewChild('assign_popup')  assign_popup : TemplateRef<any>;
  
  selected_appoinmentDurationCode : string;
  dialogRef:any;
  showSpinner:boolean = false ;
  eligibleStaffsList:any =[];
  filtered_eligibleStaffsList:any =[];
  selectedStaff:any;
  img_serverPath:string;
  protected isDrivingTime : boolean = false;
 
  @Input() scheduleStaff_Input_Data : scheduleStaff_Res ;
  user_type : any  ;

  @ViewChild('update_clock_in_out_time')  update_clock_in_out_time : TemplateRef<any>;

  missed_clock_in_out_inputs: missed_clock_in_out_inputs  ;

  @Input() showActionButtons : boolean = true ;
  @Input() allowToUpdateTime : boolean = true ;
  @Input() parent_shiftDetails : any ;
  eligible_staffs_for_clock_in_out :any [] = [] ;

  @ViewChild("comment_popup", {static:true})comment_popup : TemplateRef<any>;
  comment_text : string = "" ;

  allowToMarkStaffAbsent:boolean = false ;
  
  showMoileNo : boolean = true ;
  
  constructor(
    private dialog: MatDialog,
    private _newCardSVC: NewJobCardService,
    public _commonSVC : CommonService,
    private _sessionSVC: SessionStorageService,
    private _checkOutMissedSVC : CheckOutMissedService,
    private common_api_service : CommonApiService
  )
  {  }

  
  ngOnInit() {

    this.user_type = this._sessionSVC.get("user_type");
    this.img_serverPath = this._newCardSVC._apiSVC.getSuperAdmin() + '/downloadFile/';


    if (this.scheduleStaff_Input_Data) {
      if (this.scheduleStaff_Input_Data.inBreak) {
        this.scheduleStaff_Input_Data.breakStart_timer$ = this.createTimerObservable(this.scheduleStaff_Input_Data.breakStartDateTimeFixedFormat, this.scheduleStaff_Input_Data.timeZoneName);
      }

      if (this.scheduleStaff_Input_Data.breakDetailList && this.scheduleStaff_Input_Data.breakDetailList.length > 0) {
        this.scheduleStaff_Input_Data.breakDetailList.map(val => val.paidStatus = val.paidStatus == true || (typeof val.paidStatus === 'string' && val.paidStatus.toLowerCase() === 'paid') ? true : false)
      }

      // setting badge
      this.scheduleStaff_Input_Data.status_badge = this.badgeClass(this.scheduleStaff_Input_Data?.status);

    }

    this.subscribeToCustomerViewLimit();


  }

  changeStaff(appointmentsDurationCode:string){
    this.selected_appoinmentDurationCode = appointmentsDurationCode;
    this.getEligibleStaffsByAppointmentsDurationCode(appointmentsDurationCode); 
     this.dialogRef = this.dialog.open(this.assign_popup, {
       disableClose: true,
       width: '700px', 
       panelClass : 'popup-pos'
      });
    }

    getEligibleStaffsByAppointmentsDurationCode(appoinmentDurationCode:string){
      this.showSpinner = true;
     this._newCardSVC.getEligibleStaffsByAppointmentsDurationCode(appoinmentDurationCode).subscribe((res:any) =>{
      this.showSpinner = false;
      if(res.responsecode == 200){
        this.isDrivingTime = (res?.IsDrivingTime && res?.IsDrivingTime === 'true' ) ? true : false ;
        this.eligibleStaffsList =  res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;  
         this.selectedStaff =  this.eligibleStaffsList.find(ele => ele.isAssigned); 
    }
     });
    }

    closePopup()
    {
      if(this.isDialogOpen())
      {
        this.dialogRef.close();
      }

        this.filtered_eligibleStaffsList = [];
      this.eligibleStaffsList = [];
       this.selectedStaff = null;
       this.selected_appoinmentDurationCode = '';
  
    }

    isDialogOpen(): boolean {
      return this.dialogRef ? this.dialogRef.getState() === 0 /* MatDialogState.OPEN */ : false;
    }
    
    AssignStaff(){
      
      if(this.selectedStaff.worker.workerCode)
      {
        this._commonSVC.progressLoader = true;
  
        this._newCardSVC.assignJob(this.selected_appoinmentDurationCode , this.selectedStaff.worker.workerCode).subscribe((res:any) =>{
          this._commonSVC.progressLoader = false;
          if (res.responsecode == 200) {
            this._commonSVC.successMessage = true;
            this._commonSVC.message = res.message;
            this.closePopup();
  
            this.emitter_refreshList.emit('');
            
           }
          else {
             this._commonSVC.message = res.message;
            this._commonSVC.errorMessage = true;
            
          }
        })
      }
    
     }



     saveClockOutDetail(staffDetails:any){
      
      const { clockinoutDetailList = [], ...rest } = staffDetails;
      // const [clockinoutDetail] = clockinoutDetailList;

      const clockinoutDetail = clockinoutDetailList.length > 0 ? clockinoutDetailList[(clockinoutDetailList.length - 1)] : {} ;

    //  console.log("clockinoutDetail", clockinoutDetail) ;

      const convertedObj = {
          ...rest,
          ...clockinoutDetail
      };

      if(convertedObj){

        const dialogRef = this.dialog.open(ClockedOutPopUp, {
          disableClose: true,
          width: '700px',
          panelClass : 'popup-pos',
          data: convertedObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result) {
              this.emitter_refreshList.emit('');
            }
        });
      }
     }

     saveCheckInDetail(staffDetails:any)
     {
      
       const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
         width: '400px',
         data: { msg: "Are you sure you want to "+ staffDetails.clockInOutButton +" " + staffDetails.workerName }
       });
   
     dialogRef.afterClosed().subscribe(result => {
        
       if (result) {
   
         let reqJSON ={
           "appointmentsDurationCode": staffDetails.appointmentsDurationCode,
           "breakList": [],
           "latitude": null,
           "longitude": null,
           "noBreakNeeded": true,
           "organizationCode": this._sessionSVC.get('orgCode'),
           "workerCode": staffDetails.workerCode
         }
     
         this._newCardSVC.saveClinicianCheckinDetail(reqJSON).subscribe((res:any) =>{
           this._commonSVC.progressLoader = false;
           if (res.responsecode == 200) {
             this._commonSVC.successMessage = true;
             this._commonSVC.message = res.message;
             this.emitter_refreshList.emit('');
              //this.updateParent.emit('');
             }
           else {
              this._commonSVC.message = res.message;
             this._commonSVC.errorMessage = true;
             
           }
         })
   
       }
     });
    }


    UnAssignStaff(staffName, appoinmentDurationCode:string)
    {
 
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '400px',
          data: { msg: "Are you sure you want to UnAssign Staff "+ staffName }
        });
    
      dialogRef.afterClosed().subscribe(result => {
         
        if (result) {
    
          this._commonSVC.progressLoader = true;
  
          this._newCardSVC.UnAssignStaff(appoinmentDurationCode).subscribe((res:any) =>{
            this._commonSVC.progressLoader = false;
            if (res.responsecode == 200) {
              this._commonSVC.successMessage = true;
              this._commonSVC.message = res.message;
              
              this.closePopup();
              this.emitter_refreshList.emit('');
              
             }
            else {
               this._commonSVC.message = res.message;
              this._commonSVC.errorMessage = true;
              
            }
          })
    
        }
      });

 
    }


    private createTimerObservable(startTime: string , timezone:string):Observable<any> {
      return interval(1000).pipe(
        map(() => {
          // let timezone_formate = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')
          // const currentTime = new Date(timezone_formate).getTime();
          // const elapsedTime = currentTime - new Date(startTime).getTime();
          // let diff = moment(moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')).diff(startTime)
          //let diff = moment(startTime).diff(moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss'))
          return this.diff(startTime,timezone);
       
        })
      );
    }
    
    private diff(givenDateString: string , timezone:string)
    {
      const givenDate = moment.tz(givenDateString, timezone);
    
     const currentDate = moment.tz(timezone);
    
    //  const difference = givenDate.diff(currentDate);   // for showing in decresed order
    
     const difference = currentDate.diff(givenDate);
    
     const duration = moment.duration(difference);
    const daysDifference = Math.floor(duration.asDays());
    const hoursDifference = duration.hours();
    const minutesDifference = duration.minutes();
    const secondsDifference = duration.seconds();
    
    // console.log('Difference in days:', daysDifference);
    // console.log('Difference in hours:', hoursDifference);
    // console.log('Difference in minutes:', minutesDifference);
    // console.log('Difference in seconds:', secondsDifference);
    
    return `${hoursDifference}h ${minutesDifference}m ${secondsDifference}s`;
    
    } 

   

     updateClockInOutTime(element_data:any, parent_element_data:any)
    {
 
      this._commonSVC.progressLoader = true;
      this.common_api_service.getPendingStafForClockInout(
       this._sessionSVC.get('orgCode'),
       this.parent_shiftDetails?.appointmentsCode,
       this.parent_shiftDetails?.appointmentsProceduresCode,
       this.parent_shiftDetails?.jobDateFixedFormat
     ).subscribe((res: any) => {
       this._commonSVC.progressLoader = false;
       this.eligible_staffs_for_clock_in_out = res?.data || [] ;

       let staffObj = {
        name : parent_element_data?.workerName,
        workerLogo : parent_element_data?.workerLogo,
        staffCode : parent_element_data?.workerCode,
        email : parent_element_data?.email,
        mobileNo : parent_element_data?.mobileNo
       }

      this.missed_clock_in_out_inputs = {
        appointmentsDurationCode: parent_element_data?.appointmentsDurationCode,
        jobDate: parent_element_data?.jobDate,
        jobFormatedDate: parent_element_data?.jobDateFixedFormat,
        workerCode: parent_element_data?.workerCode,

        clinicianCheckinDetilsCode: element_data ? (element_data?.clinicianCheckinDetilsCode ? element_data.clinicianCheckinDetilsCode : null) : null,
        show_prefield_clock_in_out_data: true,
        prefield_clock_in:  element_data ? (element_data?.clockInTime ? element_data.clockInTime : null) : '',
        prefield_clock_out: element_data ? (element_data?.clockOutTime ? element_data.clockOutTime : null) : '',
        shift_start_time : parent_element_data?.startDateTimeFixedFormat,
        is_timecard_approved : element_data?.isApproved,

        staff_details_obj : staffObj,
        breakDetails : element_data?.breakDetailsReqList || [],
        comment : element_data?.comment
      // shift_end_time : ''
      }
       
      let clockInOutList : any[] = parent_element_data?.clockinoutDetailList || [] ;

      // if got data inside clockInOutList that means staff is present and already clocked-in
      this.allowToMarkStaffAbsent = clockInOutList.length > 0 ? false : true ;


      // console.log("missed_time_card_inputs", this.missed_clock_in_out_inputs);
    
      this.dialogRef = this.dialog.open(this.update_clock_in_out_time, {
        width: '500px',
        disableClose: false,
        panelClass : 'popup-pos'
       });
    
      this.dialogRef.afterClosed().subscribe(result => {
        this.reset_clock_in_inputs() ;
      });

     });


     
    }
    
    reset_clock_in_inputs()
    {
      this.missed_clock_in_out_inputs = {
        appointmentsDurationCode : null ,
        clinicianCheckinDetilsCode :  null,
        jobDate : '' ,    
        jobFormatedDate : '' ,    
        workerCode : '' ,    
        shift_start_time : '',
       // shift_end_time : ''
      } ;
    }



    refreshList()
   {
  this.dialogRef.close();
  this.emitter_refreshList.emit('');
    }



  update_missed_clock_in_out_data(data: any) {

    let req_body = {

      "appointmentsDurationCode": data.missedClockInOutInputs.appointmentsDurationCode,
      "clinicianCheckinDetilsCode": data.missedClockInOutInputs.clinicianCheckinDetilsCode ? data.missedClockInOutInputs.clinicianCheckinDetilsCode : null,
      "date": data.missedClockInOutInputs.jobFormatedDate ? data.missedClockInOutInputs.jobFormatedDate : moment(data.missedClockInOutInputs.jobDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),

      "staffCode": data.staffCode,

      "breakDetailsReqList": data.formData.breakForm || [],
      "clockinTime": data.formData.clock_in + ':00',
      "clockoutTime": data.formData.clock_out + ':00',

      "comment": data.formData.comment,

      "isAdmin": (this._sessionSVC.get('user_type') !== "Staff" && this._sessionSVC.get('user_type') !== "Customer") ? true : false,
      "organizationCode": this._sessionSVC.get('orgCode'),

      "isApproved": data.isApproved ? true : false,
      "noteRequest": data.noteRequest

    }

    this._commonSVC.progressLoader = true;

    this._checkOutMissedSVC.saveOrUpdateAdminActionTimeSheet(req_body).subscribe((res: any) => {
      // console.log("res", res);
      this._commonSVC.progressLoader = false;

      if (res.responsecode === 200)
         {
          
        // this._commonSVC.successMessage = true;
        // this._commonSVC.message = res.message;

        if (this.missed_clock_in_out_inputs.is_timecard_approved) 
          {
          const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
            width: '600px',
            disableClose: true,
            data: {
              msg: `After saving the shift time changes,
                please visit the appropriate timecards and invoices to verify the changes.
               Please communicate these changes to customers if appropriate as the system does not automatically notify the customers of these changes.`,

               confirmBtnLabel: 'Ok',
               hideNoBtn: true
            }
          });

        }
        else
        {
           this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
        }
        

        this.refreshList();
      }
      else {
        this._commonSVC.errorMessage = true;
        this._commonSVC.message = res.message;
      }

    })

  }

 
 staff_absent_Or_NoShow(event:any)
 {
 let confirm_msg:string = '';

 if(event?.staff_details_obj?.name)
 {
  confirm_msg = `Are you sure want to mark staff  "${event?.staff_details_obj?.name}" as ${event.absent_or_norShow_flag}?`;
 }
 else
 {
   confirm_msg = `Are you sure want to mark as ${event.absent_or_norShow_flag}?`;
 }

  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '400px',
    data: { msg: confirm_msg }
  });

  dialogRef.afterClosed().subscribe(result => {

    if (result && result.toLowerCase() === 'ok') 
      {
       this.changeStaffStatus(event);
    }
  
  });
 }

 
 changeStaffStatus(data:any)
 {
  const req_body = {
  "absentOrNoShow": data.absent_or_norShow_flag ? data.absent_or_norShow_flag : '',
  "appointmentsDurationCode": data.appointmentsDurationCode,
  "comment" : data?.comment ? data?.comment : ''
  }

//  console.log("changeStaffStatus" , req_body)

  this._commonSVC.progressLoader = true;
  this._checkOutMissedSVC.changeStaffStatus(req_body).subscribe((res:any)=>{
    this._commonSVC.progressLoader = false;

    if (res.responsecode === 200) {
      this._commonSVC.successMessage = true;
      this._commonSVC.message = res.message;
     this.refreshList();
    }
    else {
      this._commonSVC.errorMessage = true;
      this._commonSVC.message = res.message;
    }
  })
 }


showAdminComment(comment:string)
{
  this.comment_text = comment;

  const dialogref = this.dialog.open(this.comment_popup, {
    disableClose: false,
    width: '500px', 
    panelClass : 'popup-pos'
   });
   
}


 badgeClass(status:string): string {
  
  if(!status) return;

  switch (status.toLowerCase()) {
    case 'on job':
      return 'badge bg-warning';
    case 'accepted':
      return 'badge bg-success';
    case 'completed':
      return 'badge bg-primary';
    case 'on break':
      return 'badge bg-danger';
    case 'absent':
    case 'no show':
      return 'badge bg-danger';
    default:
      return '';
  }
}


subscribeToCustomerViewLimit()
{
  this._commonSVC.getCustomerViewLimits().subscribe((res:ICustomerViewLimitations)=>{

    this.showMoileNo = (res?.can_view_staff_mobile == false && this.user_type === 'Customer') ? false : true ;

  })
}


ask_if_timecard_already_updated(element_data:any, parent_element_data:any)
{
  // console.log('element_data', element_data);
  // console.log('parent_element_data', parent_element_data);

  if(element_data?.isApproved)
  {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '600px',
      disableClose: true,
      data: { 
        msg: `You are about to edit shift times for a timecard that has already been approved.
              This timecard may also be linked to a customer invoice. By editing this shift data,
               it may result in changes to approved timecards and invoices. Do you want to proceed?`,
  
        cancelBtnLabel: 'Cancel', 
        confirmBtnLabel: 'Yes'
        // hideNoBtn: true
       }
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
      if (result && result.toLowerCase() === 'ok') {
        this.updateClockInOutTime(element_data, parent_element_data);
      }
     
  
    });
  }
  else
  {
    this.updateClockInOutTime(element_data, parent_element_data);
  }
 
}

}
