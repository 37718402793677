import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-time-input-select',
  templateUrl: './time-input-dropdown.component.html',
  styleUrls: ['./time-input-dropdown.component.scss']
})
export class TimeInputSelect {

  @Input() startTime: FormControl = new FormControl();
  @Input() endTime: FormControl = new FormControl();

  startTime_Filter: any = [] = this._commonSVC.time12Hrs;
  endTime_Filter: any = [] = this._commonSVC.time12Hrs;
  endTime_TimeList: any = [] = this._commonSVC.time12Hrs;

  constructor(
    private _commonSVC : CommonService
  )
  {}


  startTimeKeyUp_ForFilter(value: any) {
    this.startTime_Filter = this._commonSVC.time12Hrs.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }

  endTimeKeyUp_ForFilter(value: any) {
    this.endTime_Filter = this.endTime_TimeList.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }

}
