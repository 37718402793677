<div class="popover" (click)="$event.stopPropagation()" (mouseleave)="leave($event)" [class.hideLink]="!isChatProfileOpen">
  <div class="txtOverLap w-100" (click)="$event.stopPropagation()" [class.hideLink]="!isChatProfileOpen"></div>
  <div class="popover-arrow" [class.hideLink]="!isChatProfileOpen"></div>
  <div class="w-100" (click)="$event.stopPropagation()">
    <div class="profile-card">
      <div class="card_Min_w d-flex">
        <div class="col justify-content-center" style="max-width: 70px; text-align: center;">
          <div class="profile-image">
            <img class="avatarImg" [src]="fileUrl && (appPopoverData?.logo || appPopoverData?.workerLogo || appPopoverData?.staffLogo || appPopoverData?.userLogo || appPopoverData?.photo) ? (fileUrl + (appPopoverData?.logo || appPopoverData?.workerLogo || appPopoverData?.staffLogo || appPopoverData?.userLogo || appPopoverData?.photo)) : 'assets/common_images/profile.jpg'"
             width="60" height="60" alt=""
             onerror="this.src='assets/common_images/profile.jpg';">                         
        </div>
        </div>
        <div class="col">
          <h5 class="profile-name text-nowrap">{{(appPopoverData?.staffName || appPopoverData?.workerName || appPopoverData?.userName || appPopoverData?.name) | capitalize }}</h5>
        </div>
     </div>

     <div *ngIf="isChatProfileOpen" class="profile-radio-sec">
         <div *ngIf="isProfileButtonEnable" class="col select-radio" [class.radio-border]="isChatProfileOpen && isChatButtonEnable" (click)="(appPopoverData?.staffCode || appPopoverData?.workerCode || appPopoverData?.userCode || appPopoverData?.code) && openProfile((appPopoverData.staffCode || appPopoverData.workerCode || appPopoverData.userCode || appPopoverData.code)); closePopover()">             
             Profile
         </div>

         <div *ngIf="isChatButtonEnable" class="col select-radio" (click)="(appPopoverData?.staffCode || appPopoverData?.workerCode || appPopoverData?.userCode || appPopoverData?.code) && technicianService.common.openPerticularChatByCode((appPopoverData.staffCode || appPopoverData.workerCode || appPopoverData.userCode || appPopoverData.code)); closePopover()">
             Chat
         </div>
     </div>
 
 </div>
  </div>  
</div>
