import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeSelectComponent } from './time-select/time-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SearchInputComponent } from './search-input/search-input.component';



@NgModule({
  declarations: [
    TimeSelectComponent,
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule ,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports :[
    TimeSelectComponent,
    SearchInputComponent,
    MatIconModule
  ]
})
export class WidgetModule { }
