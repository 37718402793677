import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  headers;
  healthProviderOwner = 'Owner';
  healthProviderAdmin = 'Admin';
  healthTechnician = 'Staff';
  customer = "Customer";

  constructor(private http: HttpClient, private service: ApiRootService) { }

  authenticateUser(formData, callback) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'platform': 'web', 'Access-Token': ['b8fa3263-4a90-41e0-a5e8-896667268b62'] });
    return this.http.post(this.service.getBaseUrl() + '/auth', formData, { headers: this.headers, observe: 'response' }).subscribe(
      (data) => {
        callback({ responsecode: 200, token: data.headers.get('authorization'), data });
      },
      (error) => {
        callback({ responsecode: 401, message: "User Name or Password Incorrect" })
      }
    )
  }

  getUserType(username, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/workerType/').subscribe(
      (data) => {
        //////console.log(data);
        callback(data);
      }
    )
  }

  checkOrganizationSetting(orgcode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/checkOrganizationSetting/' + orgcode).subscribe((data) => {
      callback(data);
    })
  }

  autoLogin(formData, callback) {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Token': ['b8fa3263-4a90-41e0-a5e8-896667268b62'] });
    return this.http.post(this.service.getRegistrationUrl() + '/autoLogin', formData, { headers: this.headers, observe: 'response' }).subscribe(
      (data) => {
        callback({ responsecode: 200, token: data.headers.get('authorization'), data });
      },
      (error) => {
        callback({ responsecode: 401, message: "User Name or Password Incorrect" })
      }
    )

  }
  getOrganizationOnboardingByOrganizationCode(code,callback){
    return this.http.get(this.service.getRegistrationUrl()+'/getOrganizationOnboardingByOrganizationCode/'+code).subscribe((data)=>{
      callback(data);
    })
  }

  getAllPagePermissionDetailsByUserCode(userCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllPagePermissionDetailsByUserCode/' + userCode).subscribe((data)=>{
      callback(data);
    });
  }

}
