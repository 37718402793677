<div class="col-md-12">
  <div class="row">
     <div class="col-md-6 align-self-center">
        <h6 class="dialog-header">Clock Out
           <span class="headerNoteTitleBtn">
           <span class="ps-4 cursor-pointer" (click)="_commonSVC.addUpdateNotes()">              
           <span class="notebtnContainer"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" />
           <span class="ps-1 noteBtnTxtInactive">Note</span>
           </span>
           </span>
           </span>
        </h6>
     </div>
     <div class="col-md-6">
        <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" matDialogClose (click)="resetNote()"></button>
        </div>
     </div>
  </div>
  <form [formGroup]="CheckOut_Form">
     <div class="row">
        <div class="w-100 d-flex">
           <div class="form-group col-md-7 col-sm-7 p-0 pe-1">
              <mat-form-field appearance="outline" floatLabel="always" >
                 <mat-label>Check Out Date</mat-label>
                 <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder="Choose a date"  
                 (dateChange)="onDateChange($event)" formControlName="checkoutDateTime"
                 [min]="clock_out_min_date">
                 <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                 <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <span class="help-block err"
                 *ngIf="CheckOut_Form.controls.checkoutDateTime.errors?.required && (submitted || CheckOut_Form.controls.checkoutDateTime.touched)">
              Check-Out Date is required</span>
           </div>

           <div class="form-group col-md-5 col-sm-5 p-0 ps-1">
             <time-picker
            [controlValue]="checkoutTime"
            [label]="'Check Out Time*'"
            [format_24_hrs]="true"
            (onChange)="handleClockOutTimeChange($event)"
           ></time-picker>
            <div class="help-block err mt-3" *ngIf="checkoutTime.hasError('required') && (submitted || checkoutTime.touched)">
               Check-Out Time is required
            </div>
         </div>

           <!-- <div class="form-group col-md-5 col-sm-5 p-0 ps-1">
              <label class="positionAbsLabel">Check-Out Time*</label>
              <input type="time" formate="12" class="form-control timeInput" [ngClass]="{'is-invalid': checkoutTime.invalid && (submitted || checkoutTime.touched)}" 
              [formControl]="checkoutTime" (change)="onTimeChange($event)" placeholder="Choose a Time" />
              <div class="help-block err mt-3" *ngIf="checkoutTime.hasError('required') && (submitted || checkoutTime.touched)">
                 Check-Out Time is required
              </div>
           </div> -->
  

        </div>
        <div class="col-lg-12 text-end mt-4">
           <button mat-button class="cancel-btn-primary me-4" matDialogClose (click)="resetNote()">Cancel</button>
           <button type="submit" mat-raised-button color="primary" (click)="saveClockOut()">Clock-Out</button>
        </div>
     </div>
  </form>
</div>