import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-logout',
  templateUrl: './confirm-logout.component.html',
  styleUrls: ['./confirm-logout.component.scss']
})
export class ConfirmLogoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmLogoutComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onSubmit(status){
    if(status == 'yes' && !this.data?.hideNoBtn){
      this.dialogRef.close('ok');
    } else {
      this.dialogRef.close();
    }
  }
}
