<div class="w-100">

    <div class="time-container">
        <div class="input-group outline-textbox">

            <div class="input-group">
                <label class="positionAbsLabel">{{label}} </label>
                <input class="time-input-ctrl"
                 type="text"
                 #timeInputTxtBox
                 [formControl]="controlValue" 
                 [placeholder]="format_24_hrs ? 'HH:mm' : 'HH:mm AM|PM'"
                (click)="openDropdown($event)"
                    (input)="InputChanged($event)"
                    (blur)="onTimeBlur()">
                <div class="input-clock-icon">
                    <button class="time-buttom" [disabled]="disabled" (click)="openDropdown($event)">
                    <mat-icon>access_time</mat-icon>
                    </button>
                </div>
            </div>


            <span class="help-block err"
                *ngIf="format_24_hrs && (controlValue?.errors?.pattern &&  controlValue?.touched)">
                Expected format: HH:mm
            </span>

            <span class="help-block err"
                *ngIf="!format_24_hrs && (controlValue?.errors?.pattern   &&  controlValue?.touched)">
                Expected format: HH:mm AM|PM
            </span>

        </div>

        <ng-template #dropdownTemplate>
            <div class="time-dropdown">
                <ul class="time-dropdown-list">
                    <li class="option">
                        <div class="d-flex">

                            <div class="time-content-scroll">
                                <ul class="invisible-scrollbar">
                                    <li [ngClass]="{'active' : selectedTimeObj?.hour === time ? true:false, 'mb-10rem':last}"
                                        *ngFor="let time of timeStream; let last = last" (click)="selectTime(time)">
                                        {{time}}
                                    </li>
                                </ul>
                            </div>

                            <div class="time-content-scroll">
                                <ul class="invisible-scrollbar">
                                    <li [ngClass]="{'active' : selectedTimeObj?.minute === minute ? true:false, 'mb-10rem':last}"
                                        *ngFor="let minute of minutes; let last = last" (click)="selectMinute(minute)">
                                        {{minute}}
                                    </li>
                                </ul>
                            </div>


                            <div *ngIf="!format_24_hrs">
                                <ul>
                                    <li [ngClass]="selectedTimeObj?.meridiem === mer ? 'active':''"
                                        *ngFor="let mer of meridiem" (click)="selectMeridiem(mer)">
                                        {{mer}}
                                    </li>
                                </ul>
                            </div>

                        </div>


                    </li>

                </ul>
            </div>
        </ng-template>

    </div>

</div>