import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { cust_st_jt_dts } from 'src/app/shared/interface/core.interface';
import { NewJobCardService } from '../new-job-card/new-job-card.service';
import { SessionStorageService } from 'angular-web-storage';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import * as moment from 'moment';
import { ApiRootService } from 'src/app/shared/api-root.service';

@Component({
  selector: 'app-roster-print',
  templateUrl: './roster-print.component.html',
  styleUrls: ['./roster-print.component.scss']
})
export class RosterPrintComponent {


  @Input() job_shortData_Modal: cust_st_jt_dts;
  @Output() emitter_handleEvent = new EventEmitter();

  // protected s_staffList : any = [];
  //protected s_staffList: any = {};
  protected page: number = 0;
  protected limit: number = 500;
  public isLoading: boolean = false;
  private columnToPrint: string[] = [];
  public dataToPrint: any[] = [];
  public isGeneratingPDF: boolean = false;
  @ViewChild('qrCodeElement', { static: false }) qrCodeElement!: any;
  rosterPrintData: any;

  private organizationName : string;
  private organizationLogo : string ;
  private organizationLogo_base64 : string ;
  private fileBaseUrl : string ;
  public disablePDF : boolean = false ;

  constructor(
    private _newCardSVC: NewJobCardService,
    private _sessionSVC: SessionStorageService,
     private  _apiSVC: ApiRootService
    ) { }

  ngOnInit(): void {
    //this.getAllScheduledStaff();
    this.fileBaseUrl = this._apiSVC.getSuperAdmin() + '/downloadFile/' ;
    this.getRosterData();
  }



  getRosterData() {
    this.isLoading = true;
    this.disablePDF = true ;
    this._newCardSVC.v3_getAllScheduledStaff(
      this._sessionSVC.get('orgCode'),
      this.job_shortData_Modal.appointmentsCode,
      this.job_shortData_Modal.appointmentsProceduresCode,
      this.job_shortData_Modal.date,
    ).subscribe((res: any) => {
      this.isLoading = false;
      if (res.responsecode == 200) {

        this.organizationName = res.data?.organizationName ;
        this.organizationLogo = res.data?.organizationLogo ;

        this.imageUrlToBase64(`${this.fileBaseUrl}${this.organizationLogo}`).then((base64:string)=>{
            this.organizationLogo_base64 = base64 ;
            this.disablePDF = false ;
        });

        res.data.week_day = moment(res.data.jobDateFixedFormat, 'YYYY-MM-DD').format('ddd');
        res.data.month_day = moment(res.data.jobDateFixedFormat, 'YYYY-MM-DD').format('D');
        res.data.month_name = moment(res.data.jobDateFixedFormat, 'YYYY-MM-DD').format('MMM');

        this.rosterPrintData = res.data;

        // console.log('RosterPrintData: ', res.data);

        // data to print PDF
        if (this.rosterPrintData.jobTypeShiftDetailsList && this.rosterPrintData.jobTypeShiftDetailsList.length > 0) {

          this.rosterPrintData.jobTypeShiftDetailsList.forEach((outer_ele) => {

            if (outer_ele.staffList && outer_ele.staffList.length > 0) {
              outer_ele.staffList.forEach((inner_ele) => {

                this.dataToPrint.push(
                  {
                    workerName: inner_ele.workerName,
                    role: outer_ele.jobTypeName,
                    clockInTime: '',
                    clockOutTime: '',
                    comment: ''
                  }
                )

              });
            }

          });

        }

      }
      else {
        this.rosterPrintData = null;
      }

    })
  }


  // getAllScheduledStaff(isScroll?) {

  //   if (!isScroll) {
  //     this.isLoading = true;
  //   }

  //   this._newCardSVC.getAllScheduledStaff(
  //     this._sessionSVC.get('orgCode'),
  //     this.job_shortData_Modal.appointmentsCode,
  //     this.job_shortData_Modal.appointmentsProceduresCode,
  //     this.job_shortData_Modal.date,
  //     this.page,
  //     this.limit
  //   ).subscribe((res: any) => {

  //     if (!isScroll) {
  //       this.isLoading = false;
  //     }

  //     if (res.responsecode == 200) {

  //       if (isScroll) {
  //         this.s_staffList.jobDurationDetailsList = [...this.s_staffList.jobDurationDetailsList, ...res.data.jobDurationDetailsList]
  //       }
  //       else {

  //         this.s_staffList = res.data;
  //       }

  //       if( this.s_staffList.jobDurationDetailsList?.length > 0)
  //       {
  //         this.dataToPrint = this.s_staffList.jobDurationDetailsList?.map((item, index) => [
  //            item.workerName,
  //            item.mobileNo,
  //            item.status
  //       ]);

  //      }

  //     }
  //   })
  // }

  // onScrollStaff() {
  //   this.page += 1;
  //   this.getAllScheduledStaff('scroll');
  // }


  // refreshData(event:any){
  //   console.log('RefreshData_Rec', event);
  //     this.getAllScheduledStaff();
  //     this.emitter_handleEvent.emit(event);
  // }



  // generatePDF(): void {
  //   const htmlWidth = document.getElementById('tbl_report').offsetWidth;
  //   const htmlHeight =  document.getElementById('tbl_report').offsetHeight;

  //   const topLeftMargin = 25;

  //   let pdfWidth = htmlWidth + (topLeftMargin * 2);
  //   let pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);

  //   const canvasImageWidth = htmlWidth;
  //   const canvasImageHeight = htmlHeight;

  //   const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

  //   const data = document.getElementById('tbl_report');
  //   html2canvas(data, {  allowTaint: true, useCORS: true}).then(canvas => {

  //     canvas.getContext('2d');
  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     let pdf = new jsPDF('landscape', 'pt', [pdfWidth, pdfHeight]);
  //     pdf.addImage(imgData, 'png', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);

  //     for (let i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage([pdfWidth, pdfHeight], 'p');
  //       pdf.addImage(imgData, 'png', topLeftMargin, - (pdfHeight * i) + (topLeftMargin * 4), canvasImageWidth, canvasImageHeight);
  //     }

  //     pdf.save(`Document ${new Date().toLocaleString()}.pdf`);
  //   });

  // }




  // generatePDF()  
  //   {

  //     // Sample items data
  //     // const itemsData = [
  //     //     { itemName: 'Water Tanks', quantity: "15", uom: "Liters", unitPrice: "1200", total: (15 * 1200).toString() },

  //     // ];

  //     const content = document.getElementById("shift-details") ;

  //    if(!content)
  //    {
  //     return ;
  //    }

  //     this.isGeneratingPDF = true ;
  //     html2canvas(content ,
  //       {
  //         scale: 0.7,   // Reduced scale for faster rendering
  //         allowTaint: true,
  //         useCORS: true,  // Enable cross-origin images
  //        // backgroundColor: null 
  //       }).then((canvas) => {

  //       const imgData_base64 = canvas.toDataURL('image/jpeg', 1.0);
  //      const pdf = new jsPDF();



  //     const pageWidth = pdf.internal.pageSize.getWidth();
  //     const pageHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pageWidth ;

  //     pdf.addImage(imgData_base64, 'JPEG', 10, 10, imgWidth,30);
  //     // pdf.addImage(imgData, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);


  //     // Generate AutoTable for item details
  //     // const itemDetailsRows = itemsData?.map((item, index) => [
  //     //     (index + 1).toString(),
  //     //     item.itemName.toString(),
  //     //     item.quantity?.toString(),
  //     //     item.uom?.toString(),
  //     //     item.total?.toLocaleString(),
  //     // ]);

  //     // const itemDetailsHeaders = ['S.No', 'Item Name', 'Quantity', 'UOM', 'Total'];
  //     // const columnWidths = [15, 90, 30, 30, 23]; // Adjust column widths as needed
  //     // // Define table styles
  //     // const headerStyles = {
  //     //     fillColor: [240, 240, 240],
  //     //     textColor: [0],
  //     //     fontFamily: 'Newsreader',
  //     //     fontStyle: 'bold',
  //     // };

  //     // pdf.setFont('Newsreader');


  //     const itemDetailsYStart = 50;

  //     autoTable(pdf,{
  //       head: [this.columnToPrint],
  //       body: this.dataToPrint,
  //       startY: itemDetailsYStart, // Adjust the Y position as needed
  //       // headStyles: {
  //       //      fontSize: 10, // Adjust the font size as needed
  //       //     font: 'Newsreader', // Set the font family
  //       //     halign: 'left',
  //       // },
  //        // adjust column as per ur requirement
  //       // columnStyles: {
  //       //     0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
  //       //     1: { cellWidth: columnWidths[1] },
  //       //     2: { cellWidth: columnWidths[2] },
  //       //     3: { cellWidth: columnWidths[3] },
  //       //     4: { cellWidth: columnWidths[4] },
  //       // },

  //       alternateRowStyles: { fillColor: [255, 255, 255] },

  //       bodyStyles: {
  //           // fontSize: 10, // Adjust the font size for the body
  //           // font: 'Newsreader', // Set the font family for the body
  //           cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
  //           textColor: [0, 0, 0], // Set text color for the body

  //       },

  //       pageBreak: "auto",
  //       margin: { top: 10, left: 13 },
  //   });

  //   // // Add summary and page numbers
  //   // const summaryYStart = pdf.internal.pageSize.getHeight() - 50;


  //   // Save the PDF 
  //   pdf.save(`Shift.pdf`);
  // this.isGeneratingPDF = false ;
  //   // pdf open in a new tab
  //   const pdfDataUri = pdf.output('datauristring');
  //   const newTab = window.open();
  //   newTab?.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);


  //     });


  // }



  generatePDF() {

    this.isGeneratingPDF = true;
    let user_type:string = this._sessionSVC.get('user_type') || '' ;

    const pdfTableHeader: string[] = ["No", "Name", "Role", "Clock-in Time", "Clock-out Time", "Comments"];
    const pdfTableData = this.dataToPrint.map((item, index) => [
      (index + 1).toString(),       // belong to col "No"
      item.workerName.toString(),   // belong to col "Name"
      item.role?.toString(),        // belong to col "Role"
      '',                           // belong to col "Clock-in Time"
      '',                           // belong to col "Clock-out Time"
      ''                            // belong to col "Comments"
    ]);

    const pdf = new jsPDF();
    const qrBase64 = this.getQRCodeBase64();


    let tableDetailsYStart = 55;
    const pageWidth = pdf.internal.pageSize.getWidth();

    

     let pdf_key_text : string [] = [];
     let pdf_value_text : string [] = []
    
     if(user_type.toLocaleLowerCase() != 'staff' && user_type.toLocaleLowerCase() != 'customer')
     {
      pdf_key_text = ['Customer', 'Job Site', 'Date', 'Shift Time'] ;
      pdf_value_text = [
        this.rosterPrintData.customerName,
        this.rosterPrintData.siteName,
         this.rosterPrintData.jobDateFixedFormat,
         this.rosterPrintData.startTime
      ]
     }
     else
     {
      pdf_key_text = ['Date', 'Shift Time'] ;
      pdf_value_text = [  this.rosterPrintData.jobDateFixedFormat,  this.rosterPrintData.startTime  ]
     }

    if(this.organizationLogo_base64)
     pdf.addImage(this.organizationLogo_base64, 'JPEG', 70, 5, 15, 15);
    
     pdf.setFontSize(12);
     pdf.setFont('custom', 'bold');
     pdf.text(this.organizationName, 90, 15);

     
    pdf.setLineWidth(0.1);  // Line width in units (you can adjust this)

    // Line color (RGB)
    pdf.setDrawColor(200, 200, 200);
    pdf.line(0, 23, 220, 23)

    pdf.setFontSize(10);
    pdf.text(pdf_key_text, 10, 30);
    pdf.setFont('custom', 'normal');
    pdf.text(pdf_value_text, 45, 30);

    if (this.rosterPrintData.clockinPin) {
      pdf.setFont('custom', 'bold');
      pdf.text('Clock-In PIN', 130, 30);

      pdf.setFont('Newsreader', 'bold');
      pdf.setFontSize(15);
      pdf.text(this.rosterPrintData.clockinPin.toString(), 155, 30);

      if (qrBase64) {
        pdf.addImage(qrBase64, 'JPEG', 130, 31, 50, 50);
      }
    }

    // if (this.organizationLogo_base64) {
    //   pdf.addImage(this.organizationLogo_base64, 'JPEG', ((pageWidth / 2) + 15), 14, 50, 50);
    // }

    if (this.rosterPrintData.clockinPin) {
      tableDetailsYStart = 90;
    }

    pdf.setFontSize(15);
    pdf.text("Shift Roster", ((pageWidth / 2) - 15), (tableDetailsYStart - 5));



    autoTable(pdf, {
      head: [pdfTableHeader],
      body: pdfTableData,
      startY: tableDetailsYStart, // Adjust the Y position as needed
      // headStyles: {
      //      fontSize: 10, // Adjust the font size as needed
      //     font: 'Newsreader', // Set the font family
      //     halign: 'left',
      // },
      // adjust column as per ur requirement
      // columnStyles: {
      //     0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
      //     1: { cellWidth: columnWidths[1] },
      //     2: { cellWidth: columnWidths[2] },
      //     3: { cellWidth: columnWidths[3] },
      //     4: { cellWidth: columnWidths[4] },
      // },

      alternateRowStyles: { fillColor: [255, 255, 255] },

      bodyStyles: {
        // fontSize: 10, // Adjust the font size for the body
        // font: 'Newsreader', // Set the font family for the body
        cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
        textColor: [0, 0, 0], // Set text color for the body

      },

      pageBreak: "auto",
      margin: { top: 10, left: 13 },
      theme: 'grid'
    });

    this.isGeneratingPDF = false;
    // Save the PDF 
    pdf.save(`Roster.pdf`);

  }


  getQRCodeBase64() {
    let base64: any = '';
    if (this.qrCodeElement) {
      const canvas = this.qrCodeElement.qrcElement.nativeElement.querySelector("canvas");

      if (canvas) {
        base64 = canvas.toDataURL('image/png');
      }

    }
    return base64;
  }

  imageUrlToBase64 = async (url:string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

}
