<div class="d-flex flex-row justify-content-between w-100 mb-2 mt-3 logo
  align-items-center">
  <div class="float-start header-name">Pending Leaves
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 searchfilter text-end">
    <input autocomplete="off" matInput
      (keyup)="applyFilter($event.target.value)" type="text"
      class="form-control"
      placeholder="Search">
    <mat-icon class="cursor-pointer">search</mat-icon>
    <!-- <button mat-raised-button color="primary">Add</button> -->
  </div>
</div>
<div class="w-100">
  <div class="col-md-12">
    <mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab position-relative">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap popoverToolTipLineHeight">
          <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element?.startDate | date: element?.dateFormat}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element?.endDate | date: element?.dateFormat}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="commentWidth">Comments</th>
        <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
          <span title="{{element.comment}}">{{element.comment}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="leaveStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <span class="badge bg-warning">{{element.leaveStatus | capitalize}}</span>
         </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
        <td mat-cell *matCellDef="let element" class="action">
          
          <ng-template [ngIf]="hasPermission.editPermission === true">
          <a href="" class="action-btn btn dropdown-toggle dropdown-toggle-no-caret" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z" fill="#565658"/>
                <path d="M11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2Z" fill="#565658"/>
                <path d="M18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.89543 18 2Z" fill="#565658"/>
                </svg>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <ng-container *ngIf="element?.endDate && element.isExpired; else disabledBtn">
                <a title="End date Passed" class="dropdown-item disabled" href="javascript:void(0)"><img src="assets/images/tick-icon.svg" class="me-2">Approve</a>
                <a title="End date Passed" class="dropdown-item disabled" href="javascript:void(0)"><img src="assets/images/cross-icon.svg" class="me-2">Reject</a>                
              </ng-container>
              <ng-template #disabledBtn>
                <a class="dropdown-item" href="javascript:void(0)" (click)="approveRequest(element)"><img src="assets/images/tick-icon.svg" class="me-2">Approve</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="declineRequest(element)"><img src="assets/images/cross-icon.svg" class="me-2">Reject</a>
              </ng-template>
            </div>
           </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && distributionData.length === 0" class="p-3 noList">Pending leaves are not available</div>
    </div>
    
  </div>
</div>