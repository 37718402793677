
<mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Staff</mat-label>
    <mat-select  #staffListRef
     [formControl]="ctrl_staff" 
     placeholder="Select staff"
     (selectionChange)="onSelectionChanged($event)"
     [disabled]="disabled"
     >
      <mat-option>
  <ngx-mat-select-search  [placeholderLabel]="'Search staff'"  [noEntriesFoundLabel]="'Staff not found'"
  [formControl]="staffSeachInput"></ngx-mat-select-search>
</mat-option>
      <mat-option *ngFor="let staff of staffList_Filter"  [value]="staff">
        <span [style.color]="staff?.isAbsent ? 'red' : ''">{{staff.name}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>