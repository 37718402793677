
<!--  
<div class="break-form break-big-form">
<div class="row">
<form [formGroup]="parentBreakForm">
<div class="fh pt-2" formArrayName="breakForm">
<ng-container *ngFor="let control of getBreakForm.controls; let i = index ; let last = last;">
  <div [formGroupName]="i">
<div class="row mb-2">

   <div class="col-lg-3">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Break Type</mat-label>
                <input matInput formControlName="breakType" type="text"
                  >
              </mat-form-field>
              <span class="help-block err"
              *ngIf="control.get('breakType').errors?.required && (is_formSubmitted || control.get('breakType').touched)">
              Break type is required
            </span>
          </div>

          <div class="col-lg-3 text-center">
            <div>
           <mat-checkbox
            formControlName="paidStatus" 
            [labelPosition]="'before'">
            Paid
             </mat-checkbox>
    </div>
          </div>

        <div class=" col-lg-3">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Duration</mat-label>
            <input class="minute_input" matInput formControlName="duration"  type="text" placeholder="Ex. 00">
            <i class="minute" matSuffix>Min</i>
          </mat-form-field>

          <span class="help-block err"
          *ngIf="control.get('duration').errors?.required && (is_formSubmitted || control.get('duration').touched)">
          Please select duration
        </span>
          <span class="help-block err"
            *ngIf="control.get('duration').errors?.pattern && (is_formSubmitted || control.get('duration').touched)">
            Duration should be between 1 to 100 (min)
          </span>
  
     </div>

     <div class="col-3 text-end">
       <button mat-icon-button color="warn" class="me-2" *ngIf="!last" (click)="removeBreak(i)" >
        <mat-icon>delete</mat-icon>
      </button>
     <button mat-icon-button color="primary" *ngIf="last" (click)="addBreak()" >
      <mat-icon>add_circle</mat-icon>
    </button>
    </div>
 
</div>
</div>
</ng-container>
</div>
</form>


<div class="row mt-3" *ngIf="getBreakForm.controls.length > 0">
  <div class="col-lg-12 text-end">
    <button mat-raised-button color="primary" (click)="validateForm()" >OK</button>
  </div>
 </div>


</div>
</div> -->


<div class="break-form break-small-form">
  <div class="row">
    <form [formGroup]="parentBreakForm">
    <div class="fh pt-2" formArrayName="breakForm">
    <ng-container *ngFor="let control of getBreakForm.controls; let i = index ; let last = last;">
      <div [formGroupName]="i">
    <div class="row g-1 mb-2">
    
       <div class="col-lg-3">
                     <input  formControlName="breakType" type="text" class="form-control"
                     placeholder="Break Type" (blur)="validate_and_emmit()"
                     [ngClass]="{'invalid-field' : control.get('breakType').errors?.required && (is_formSubmitted || control.get('breakType').touched)} " >
                   <!-- <span class="help-block err"
                  *ngIf="control.get('breakType').errors?.required && (is_formSubmitted || control.get('breakType').touched)">
                  Break type is required
                </span> -->
              </div>
     
            <div class=" col-lg-3">
              <div class="input-group">
                 <input class="minute_input w-75" formControlName="duration"  class="form-control"
                  type="text" placeholder="Duration" (blur)="validate_and_emmit()"
                  [ngClass]="{'invalid-field' : control.get('duration').errors?.required && (is_formSubmitted || control.get('duration').touched) } ">
                 <span class="input-group-text">Min</span>
                </div>
                <!-- <i class="minute" matSuffix>Min</i> -->
                 <!-- <span class="help-block err"
              *ngIf="control.get('duration').errors?.required && (is_formSubmitted && control.get('duration').touched)">
              Please select duration
            </span> -->
              <span class="help-block err"
                *ngIf="control.get('duration').errors?.pattern && (is_formSubmitted && control.get('duration').touched)">
                Duration should be between 1 to 100 (min)
              </span>
      
              <!-- <div class="text-end mt-1" *ngIf="getBreakForm.controls.length > 0 && last">
                <button class="btn-ok" (click)="validateForm()" >ok</button>
              </div> -->

         </div>

         <div class="col-lg-2 text-center">
          <div class="ms-2 d-flex align-items-center">
         <input type="checkbox" formControlName="paidStatus" (change)="onChxChange(i)"> 
         <span class="ms-2" style="font-size: 12px;">Paid</span> 
        </div>
        </div>
    
         <div class="col-2 text-end mat-buttons">
          <mat-icon class="text-primary me-2"   (click)="addBreak()">add_circle</mat-icon>
           <mat-icon class=" text-danger" (click)="removeBreak(i)">delete</mat-icon>
            
            <!-- <mat-icon class="text-primary" *ngIf="last" (click)="addBreak()">add_circle</mat-icon> -->
            <!-- <mat-icon class="ms-2 text-danger" *ngIf="getBreakForm.controls.length > 1 && last" (click)="removeBreak(i)">delete</mat-icon> -->
       
        </div>
     
    </div>
    </div>
    </ng-container>
    </div>
    </form>
    
    
    <!-- <div class="row mt-3" *ngIf="getBreakForm.controls.length > 0">
      <div class="col-lg-12 text-end">
        <button class="btn-ok" (click)="validateForm()" >ok</button>
      </div>
     </div> -->
    
    
    </div>
</div>