import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Itimes } from '../../interface/core.interface';
import { CommonService } from '../../common.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ViewContainerRef } from '@angular/core';

@Component({
  selector: 'time-picker',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss']
})
export class TimeSelectComponent implements OnInit {

  @Input() format_24_hrs: boolean = true;
  @Output() timeEvent = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Input() label: string = "";
  @Input() disabled: boolean = false;

  @Input() selectedValue: Itimes = {
    hour: '',
    minute: '',
    meridiem: ''
  }

  @Input() controlValue: FormControl = new FormControl();

  timeStream: string[] = [];

  minutes: string[] = [];
  meridiem: string[] = ['AM', 'PM'];



  selectedTimeObj: Itimes = {
    hour: '',
    minute: '',
    meridiem: ''
  };


  // private input_data_changes_observable = new Subject<any>();
  // private clear_observable = new Subject();
  @ViewChild('dropdownTemplate') dropdownTemplate: TemplateRef<any>;
  @ViewChild("timeInputTxtBox") timeInputTxtBox : HTMLElement;

  private overlayRef: OverlayRef | null = null;

  constructor(
    private commonService: CommonService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) { }


  ngOnInit(): void {

    if (this.format_24_hrs) {
      this.timeStream = this.range(0, 24, 1);
      this.controlValue.addValidators(Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] ?$/i));
    }
    else {
      this.timeStream = this.range(1, 13, 1);
      this.controlValue.addValidators(Validators.pattern(/^(0[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM) ?$/i));
    }

    this.controlValue.updateValueAndValidity();

    this.minutes = this.range(0, 60, 1);

    // this.subscribe_date_change();
    this.converTimeToAppropriateFormat();


  }


  range = (start, stop, step) =>
    Array.from(
      { length: Math.ceil((stop - start) / step) },
      (_, i) => (start + i * step),
    ).map((index) => index <= 9 ? `0${index}` : `${index}`);



  // generate_24Hrs_time_format()
  // {
  //  return Array.from({length:24}).map((ele, index)=> index <=9 ? `0${index}` : `${index}`);
  // }

  // generate_12Hrs_time_format()
  // {
  //  return Array.from({length:13}).map((ele, index)=> index <=9 ? `0${index}` : `${index}`);
  // }

  // generateMinutes()
  // {
  //   return Array.from({length:60}).map((ele, index)=> index <=9 ? `0${index}` : `${index}`);
  // }


  selectTime(hour: string) {
    this.selectedTimeObj.hour = hour;
    this.controlValue.setValue(this.formateTo24());
    this.emitTime();
  }

  selectMinute(minute: string) {
    this.selectedTimeObj.minute = minute;
    this.controlValue.setValue(this.formateTo24());
    this.emitTime();
  }

  selectMeridiem(mer: string) {
    this.selectedTimeObj.meridiem = mer;
    this.controlValue.setValue(this.formateTo24());
    this.emitTime();
  }


  formateTo24() {
    if (this.format_24_hrs)
      return `${this.selectedTimeObj.hour}:${this.selectedTimeObj.minute}`;
    else
      return `${this.selectedTimeObj.hour}:${this.selectedTimeObj.minute} ${this.selectedTimeObj.meridiem}`;
  }


  emitTime() {
    if (this.controlValue.valid) {
      //this.toggleTime = false ;

      this.closeDropdown();

      const timeObj = {
        formControl: this.controlValue,
        time: this.selectedTimeObj
      }
      this.onChange.emit(timeObj);
      // this.timeEvent.emit(this.selectedTimeObj);
    }


    // if(this.areAllPropertiesNotBlank(this.selectedTimeObj))
    // {
    //   this.toggleTime = false ;
    //   this.timeEvent.emit(this.selectedTimeObj);
    // }
    // else{
    //   this.timeEvent.emit(null);
    // }

  }


  areAllPropertiesNotBlank(obj: Record<string, any>): boolean {
    return Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
  }

  // onInputBlur()
  // {
  //   this.toggleTime = false ;
  // }


  // InputChanged(event:any)
  // {
  //   this.input_data_changes_observable.next(event);
  // }


  // subscribe_date_change() {
  //   this.input_data_changes_observable.pipe(
  //     debounceTime(300),
  //     takeUntil(this.clear_observable))
  //     .subscribe((event: any) => {
  //       this.handleTimeInputChanged(event);
  //     })
  // }

  InputChanged(event) {
    this.emitTime()
    // if(this.controlValue.valid)
    // this.onChange.emit(event);
    //this.emitTime();
  }


  converTimeToAppropriateFormat() {
    if (this.controlValue.value) {
      let val: string = this.controlValue.value;
      // formatting value
      if (this.format_24_hrs && val) {
        val = this.commonService.convertTo24HourFormat(val);
      }

      if (val && !this.format_24_hrs) {
        val = this.commonService.convertTo12HourFormat(val);
      }


      this.controlValue.setValue(val);

    }
  }



  openDropdown(event: MouseEvent) {

    
    if (this.overlayRef) {
      this.overlayRef.detach(); // Close existing dropdown if already open
    }

    // const positionStrategy = this.overlay.position()
    //   .flexibleConnectedTo(event.target as HTMLElement) timeInputTxtBox
    //   .withPositions([
    //     { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }
    //   ]);

    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo(this.timeInputTxtBox) 
    .withPositions([
      { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }
    ]);

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,  // To close the dropdown when clicking outside
      backdropClass: 'custom-backdrop',
      positionStrategy
    });

    // Attach the template dropdown portal
    const dropdownPortal = new TemplatePortal(this.dropdownTemplate, this.viewContainerRef);
    this.overlayRef.attach(dropdownPortal);

    // Close dropdown on backdrop click
    this.overlayRef.backdropClick().subscribe(() => this.closeDropdown());
  }

  closeDropdown() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  onTimeBlur()
  {
    if(this.controlValue.valid && this.controlValue.value)
      this.controlValue.setValue( this.controlValue.value.trim());
  }


}
