import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiRootService } from "./api-root.service";
import { SessionStorageService } from "angular-web-storage";
import { Observable } from "rxjs/internal/Observable";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmLogoutComponent } from "../common/confirm-logout/confirm-logout.component";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { CryptoService } from "./services/crypto.service";
import * as moment from "moment";
import { RxStompService } from "../rx-stomp.service";
import { IBtnControlsPermission, ICalendarDate, IChatCountResponse } from "./interface/core.interface";
//import { NotificationService } from 'src/app/dashboards/providers-dashboard/notification/notification.service';
import { AddUpdateNoteComponent } from "src/app/components/common-notes/add-update-note/add-update-note.component";
import { GroupedNoteListComponent } from 'src/app/components/common-notes/grouped-note-list/grouped-note-list.component';
import { TechnicianEditComponent } from "src/app/dashboards/providers-dashboard/technician/technician-edit/technician-edit.component";
import { PatientComponent } from "src/app/dashboards/providers-dashboard/patient/patient.component";
import { IPagePermissionResponse } from "./interface/login.interface";
import { ChatService } from 'src/app/dashboards/providers-dashboard/chat/chat.service';
import { ChatComponent } from 'src/app/dashboards/providers-dashboard/chat/chat.component';
import { ICustomerViewLimitations } from "./interface/ICustomerViewLimits";
import { RepeatJobComponent } from 'src/app/components/repeat-job/repeat-job.component';


@Injectable({
  providedIn: "root",
})
export class CommonService {
  count: any = 10;
  pageOffset: any = 0;
  private Interval:any = null;
  notificationList:any;
  currentpage = 0;
  name = new RegExp("^[a-zA-Z'-][a-zA-Z '-]+[a-zA-Z'-]$");
  email = new RegExp('^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$');
  namemin = new RegExp("^(?=[a-zA-Z'-]{2,})[a-zA-Z '-]+[a-zA-Z'-]$");
  username = new RegExp("^[a-zA-Z0-9_.-]*$");
  organizationname = new RegExp(
    "^[a-zA-Z0-9'-_&][a-zA-Z0-9 '-_&]+[a-zA-Z0-9'-_&]$"
  );
  address = new RegExp(
    "^[a-zA-Z0-9'-_&*,][a-zA-Z0-9 '-_&*,]+[a-zA-Z0-9'-_&*,]$"
  );
  phonenumber = new RegExp("^[0-9-]*$");
  numberdot = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
  shiftname = new RegExp("^[a-zA-Z0-9][a-zA-Z0-9 ]+[a-zA-Z0-9]$");
  skillname = new RegExp("^[a-zA-Z-][a-zA-Z -]+[a-zA-Z-]$");
  currency = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
  password = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
  );
  postCode = new RegExp("^[0-9 -]+$");
  onlyNameWithSpace = new RegExp("^[a-zA-Z -]*$");
  numberDecimal = new RegExp("^[0-9]*.?[0-9]+$");
  onlyDigits = new RegExp("^[0-9]+$");
  organizationStatus;
  validatePendingOrgList = [
    { name: "ProfileComponent" },
    { name: "NotificationComponent" },
    { name: "ProvidersDashboardComponent" },
    { name: "SettingsComponent" },
    { name: "OnboardingComponent" },
    { name: "AppointmentComponent" },
  ];
  CountryCode = "1";
  apiStopError = "Something went wrong";
  islogin = false;
  progressLoader = false;
  toggle = "show-nav";
  mainContent = "navClose";
  mobtoggle = "mobhide-nav";
  timeIntervals = [];
  offHours = [
    { time: "00:15" },
    { time: "00:30" },
    { time: "01:00" },
    { time: "02:00" },
  ];
  hoursFormat = [
    { format: "hh:mm a", name: "12 Hours" },
    { format: "HH:mm", name: "24 Hours" },
  ];
  priority = [
    { name: "Not Allowed", priority: "00" },
    { name: "01", priority: "01" },
    { name: "02", priority: "02" },
    { name: "03", priority: "03" },
    { name: "04", priority: "04" },
    { name: "05", priority: "05" },
    { name: "06", priority: "06" },
    { name: "07", priority: "07" },
    { name: "08", priority: "08" },
    { name: "09", priority: "09" },
  ];
  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  days = [
    { name: "Mon", value: 0, checked: false },
    { name: "Tue", value: 1, checked: false },
    { name: "Wed", value: 2, checked: false },
    { name: "Thu", value: 3, checked: false },
    { name: "Fri", value: 4, checked: false },
    { name: "Sat", value: 5, checked: false },
    { name: "Sun", value: 6, checked: false },
  ];
  orgCode;
  workerCode;
  message;
  successMessage = false;
  errorMessage = false;
  loginmessage;
  loginsuccessMessage = false;
  loginerrorMessage = false;
  providerImage;
  imageLogo;
  healthProviderOwner = "Health Provider Owner";
  healthProviderAdmin = "Health Provider Admin";
  healthTechnician = "Health Technician";
  settingsMessage;
  allNotifications = [];
  allNotificationsCount;
  opened = true;
  progressText;
  percentageLoader = false;
  language = [
    { name: "English", value: "english" },
    { name: "French", value: "french" },
    { name: "Germany", value: "germany" },
  ];

  dashboardColorData = [];
  routesArray = [];
  unauthorized = false;
  infiniteLoader = false;
  durationHours = [];
  superAdminUrl = this.service.getSuperAdmin() + "/downloadFile/";
  webLogo;
  orgLogo;

  @Output() confirmEmit = new EventEmitter();
  sub: Subscription;
  showDuration: any;
  showDistance: any;
  commonPassword = "NPS@1074";
  globalChatCount:number = 0;
  allGroupResponse: any;
  globalCancelJobRequestCount = 0;
  globalLeaveCount = 0;
  globalImage;
  globalImageUrl;
  dataSourceNotification = new MatTableDataSource();

  @Output() orgCodeEmit = new EventEmitter();

  // refreshDashboardSubject = new Subject();
  // private refreshInterval: any;

  time12Hrs: any[] = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];

  private nonWorkingDayDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nonWorkingDayData$: Observable<any> = this.nonWorkingDayDataSubject.asObservable();

  dialogRef: any;

  CardCalendarDays_Strip : ICalendarDate[] = [];
  dateFormat_YYYY_MM_DD:string = "YYYY-MM-DD";


 timeZone_Sub: Subject<any> = new Subject<any>();
 timeZoneObs$: Observable<any> = this.timeZone_Sub.asObservable();

 noteRequest: any = {
  isPublic: true,
  note: null
};

 private subjectVal = new Subject<any>();
 
 private $customer_view_limits = new BehaviorSubject<ICustomerViewLimitations>( {
  can_view_staff_name : false,
  can_view_staff_email : false,
  can_view_staff_mobile : false,
  can_view_staff_profile_pic : false,
});

  constructor(
    private http: HttpClient,
    private service: ApiRootService,
    public local: SessionStorageService,
    public dialog: MatDialog,
    public route: Router,
    private _cryptoService: CryptoService,
    private _rxStompService: RxStompService,
    public ChatService: ChatService,
    // public notificationService: NotificationService,
  ) 
  {
    this.gethours();
  }
  /* 
  name: srinivas
  use: return hours.
  */
  gethours() {
    this.durationHours = [];
    var i, j;
    for (i = 0; i < 24; i++) {
      for (j = 0; j < 4; j++) {
        let minutes = j === 0 ? "00" : 15 * j;
        let minute = j === 0 ? "" : 15 * j + " minutes";
        if (i == 0) {
          if (j !== 0) {
            this.durationHours.push({
              text: minute,
              value: "00:" + minutes + ":00",
            });
          }
        } else {
          this.durationHours.push({
            text: i + (i == 1 ? " hour" : " hours") + " " + minute,
            value:
              i < 10
                ? "0" + i + ":" + minutes + ":00"
                : i + ":" + minutes + ":00",
          });
        }
      }
    }
    ////console.log(this.durationHours);
    // this.durationHours = arr;
  }
  /* 
  name: srinivas
  use: return time details.
  */
  getTimeSlot() {
    try {
      if (this.local.get("orgCode")) {
        this.getTimeSlotByOrganisationCode(
          this.local.get("orgCode"),
          (response) => {
            if (response.responsecode == 200) {
              this.timeIntervals = response.data;
            }
          }
        );
      }
    } catch (error) { }
  }
  /* 
  name: srinivas
  use: get all colors name by percentage.
  */

  getpercentData() {
    for (let i = 0; i <= 100; i++) {
      if (i == 0 || i <= 10) {
        this.dashboardColorData.push({ percent: i, class: "reddark" });
      } else if (i >= 11 && i <= 20) {
        this.dashboardColorData.push({ percent: i, class: "redmed" });
      } else if (i >= 21 && i <= 30) {
        this.dashboardColorData.push({ percent: i, class: "redlight" });
      } else if (i >= 31 && i <= 40) {
        this.dashboardColorData.push({ percent: i, class: "yellowdark" });
      } else if (i >= 41 && i <= 50) {
        this.dashboardColorData.push({ percent: i, class: "yellowmeddark" });
      } else if (i >= 51 && i <= 60) {
        this.dashboardColorData.push({ percent: i, class: "yellowmedlight" });
      } else if (i >= 61 && i <= 70) {
        this.dashboardColorData.push({ percent: i, class: "yellowlight" });
      } else if (i >= 71 && i <= 80) {
        this.dashboardColorData.push({ percent: i, class: "greenlight" });
      } else if (i >= 81 && i <= 90) {
        this.dashboardColorData.push({ percent: i, class: "greenmed" });
      } else if (i >= 91 && i <= 100) {
        this.dashboardColorData.push({ percent: i, class: "greendark" });
      } else {
        this.dashboardColorData.push({ percent: 101, class: "grey" });
      }
    }
  }
  /* 
  name: srinivas
  use: return nav details.
  */
  toggleMenu(event) {
    if (this.toggle == "show-nav") {
      this.toggle = "hide-nav";
      this.mainContent = "navOpen";
    } else {
      this.toggle = "show-nav";
      this.mainContent = "navClose";
    }
  }
  /* 
  name: srinivas
  use: return mobile toggle.
  */
  toggleMobile() {
    if (this.mobtoggle == "mobshow-nav") {
      this.mobtoggle = "mobhide-nav";
      this.mainContent = "navOpen";
    } else {
      this.mobtoggle = "mobshow-nav";
      this.mainContent = "navClose";
    }
  }
  /* 
  name: srinivas
  use: return time slot details.
  */
  getTimeSlotByOrganisationCode(orgCode, callback) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getTimeSlotByOrganisationCode/" +
        orgCode
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return user details.
  */
  getUserDetails(username, callback) {
    return this.http
      .get(this.service.getRegistrationUrl() + "/getUser/")
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return email avialablity details.
  */
  checkEmailAvialablity(email, callback) {
    return this.http
      .get(this.service.getRegistrationUrl() + "/getEmailAvailability/" + email)
      .subscribe((data) => {
        callback(data);
      });
  }


    /* check email availability 
    when updating records
     */
 
    checkEmailAvailabilityForUpdate(userEmail, userCode){
      return this.http.get(this.service.getRegistrationUrl()+'/getEmailAvailabilityForUpdate/'+userEmail+'/'+userCode);
    }


  /* 
  name: srinivas
  use: return username detials.
  */
  checkUserNameAvialablity(username, callback) {
    return this.http
      .get(
        this.service.getRegistrationUrl() +
        "/checkUserNameavailability/" +
        username
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return all time zones.
  */
  getAlltimeZone(callback) {
    return this.http
      .get(this.service.getHealthCareUrl() + "/getAllTimeZoneDetail")
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return user details by username.
  */
  getOrgaizationCode() {
    try {
      this.getUserDetails(this.local.get("username"), (data) => {
        const userdata = data.data;
        this.workerCode = userdata?.userCode;
        userdata &&
          userdata?.organizationlist.forEach((element) => {
            this.orgCode = element.organizationCode;
          });
      });
    } catch (error) { }
  }

  /* 
name: Vivek Chauhan
use: return user details by username.
*/
  getAngularDropwownList(lsitData: any) {
    if (lsitData && lsitData.length > 0) {
      return lsitData;
    } else {
      return [];
    }
  }

  /* 
  name: srinivas
  use: save upload image.
  */
  uploadImage(image, callback) {
    return this.http
      .post(this.service.getSuperAdmin() + "/uploadFile", image)
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: save and upload ann type docuemnts and image.
  */
  uploadAllTypeFile(image, callback) {
    return this.http
      .post(this.service.getSuperAdmin() + "/uploadAllTypeFile", image)
      .subscribe((data) => {
        callback(data);
      });
  }

  /* 
  name: srinivas
  use: return image detail.
  */
  getImage(imagename: string): Observable<Blob> {
    return this.http.get(
      this.service.getSuperAdmin() + "/downloadFile/" + imagename,
      { responseType: "blob" }
    );
  }
  /* 
  name: srinivas
  use:return all skils by organzation code.
  */
  getSkillsByOrgCode(code, callback) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/v2/getSkillsByOrganizationCode/" +
        code
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return all certification by organization code.
  */
  getCertificationByOrgCode(code, callback) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getCertificationsMasterByOrganizationCode/" +
        code
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return all healthcare notification by user code.
  */
  getAllHealthcareNotificationByUserCodeWithUnRead(usercode, callback) {
    return this.http
      .get(
        this.service.getNotificationService() +
        "/getAllCareHuddleNotificationByUserCodeWithUnReadByPage/" +
        usercode
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return all healthcare notification by user code and with page.
  */
  getAllHealthcareNotificationByUserCodeWithReadByPage(
    usercode,
    page,
    limit,
    callback
  ) {
    return this.http
      .get(
        this.service.getNotificationService() +
        "/getAllHealthcareNotificationByUserCodeWithReadByPage/" +
        usercode +
        "?page=" +
        page +
        "&limit=" +
        limit
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return all activities by role.
  */
  getAllActivitiesByRoleCodeByPage(code, callback) {
    return this.http
      .get(
        this.service.getRegistrationUrl() +
        "/getAllActivitiesByRoleCodeByPage/" +
        code
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return worker detail.
  */
  getWorkerAndOrganizationFromWAS(code, callback) {
    //getWorkerAndOrganizationFromWAS
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/adminGetEligibleTechnicianWithoutPreferredHoursForAppointmentByAppointmentsDurationCode/" +
        code
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: return confirm popup .
  */
  showConfirmPopup() {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: "350px",
      data: {
        msg: "This page is asking you to confirm that you want to leave – If any data you have entered may not be saved?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (this.local.get("user_type") == "Health Provider Owner") {
          this.route.navigate(["/dashboard"]);
        } else {
          this.route.navigate(["/dashboard-technician"]);
        }
      }
    });
  }
  /* 
  name: srinivas
  use: return distance detail .
  */
  toggleDistance(appointmentCode) {
    // console.log(appointmentCode);
    if (!this.showDuration) {
      this.showDuration = appointmentCode;
      this.showDistance = appointmentCode;
    } else {
      this.showDuration = "";
      this.showDistance = "";
    }
  }
  /* 
  name: srinivas
  use: return all color setting by organization code.
  */
  getColorCombo(code, callback) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getDesignLocalSettingWithDefaultGlobalSettingByOrganizationCode/" +
        code
      )
      .subscribe((data) => {
        callback(data);
      });
  }
  /* 
  name: srinivas
  use: retun all colors.
  */
  getColors() {
    // alert(this.local.get('orgCode'));
    try {
      if (this.local.get("orgCode")) {
        this.getColorCombo(this.local.get("orgCode"), (res) => {
          if (res.responsecode == 200) {
            // //console.log('getColorCombo: ', res.data);
            //Primary color change for whole project
            if (res.data[2].localSettingValue != null) {
              document.documentElement.style.setProperty(
                "--main-color",
                res.data[2].localSettingValue
              );
            } else {
              document.documentElement.style.setProperty(
                "--main-color",
                res.data[2].globalSettingValue
              );
            }
            if (res.data[3].localSettingValue != null) {
              document.documentElement.style.setProperty(
                "--secondary-color",
                res.data[3].localSettingValue
              );
            } else {
              document.documentElement.style.setProperty(
                "--secondary-color",
                res.data[3].globalSettingValue
              );
            }
            if (res.data[7].localSettingValue != null) {
              document.documentElement.style.setProperty(
                "--btn-color",
                res.data[7].localSettingValue
              );
            } else {
              document.documentElement.style.setProperty(
                "--btn-color",
                res.data[7].globalSettingValue
              );
            }
            if (res.data[8].localSettingValue != null) {
              document.documentElement.style.setProperty(
                "--selection-color",
                res.data[8].localSettingValue
              );
            } else {
              document.documentElement.style.setProperty(
                "--selection-color",
                res.data[8].globalSettingValue
              );
            }
            //web logo change for the project
            if (res.data[0].localSettingValue != null) {
              this.webLogo = "assets/staffwork_logo.png";
            } else {
              this.webLogo = "assets/staffwork_logo.png";
            }
            if (res.data[14].localSettingValue != null) {
              this.orgLogo =
                this.superAdminUrl + res.data[14].localSettingValue;
            } else {
              this.orgLogo =
                this.superAdminUrl + res.data[14].globalSettingValue;
            }
            if (res.data[14].localSettingValue != null) {
              this.orgLogo =
                this.superAdminUrl + res.data[14].localSettingValue;
            } else {
              this.orgLogo =
                this.superAdminUrl + res.data[14].globalSettingValue;
            }
          }
        });
      }
    } catch (error) { }
  }
  /* 
  name: srinivas
  use: return all unread message count by organization code .
  */
  adminGetAllUnReadMessageCountByOrganizationCode(code, callback) {
    return this.http
      .get(
        this.service.getChatUrl() +
        "/adminGetAllUnReadMessageCountByAdminCode/" +
        code
      )
      .subscribe((data: IChatCountResponse) => {
        callback(data);
      });
  }

  /* 
name: VivekChauhan
use: return all unread Leave count by organization code .
*/
  getLeavePendingCount(code, callback) {
    return this.http
      .get(this.service.getHealthCareUrl() + "/getLeavePendingCount/" + code)
      .subscribe((data) => {
        callback(data);
      });
  }

/* 
name: VivekChauhan
use: return Cancel Job Request Count by organization code .
*/
// getCancelJobRequestCount(code, callback) {
//   return this.http
//     .get(this.service.getHealthCareUrl() + "/getCancelJobAssignmentCountByOrganizationCode/" + code)
//     .subscribe((data) => {
//       callback(data);
//     });
// }

getCancelJobRequestCount(OrgCode, isExpired:boolean , callback) {
  return this.http
    .get(this.service.getHealthCareUrl() + "/getAllCancelJobAssignmentByOrganizationCodeForExpiredCount/" + OrgCode +'/'+ isExpired)
    .subscribe((data) => {
      callback(data);
    });
}

  getLoginCustomerCode(code) {
    return this.http.get(
      this.service.getHealthCareUrl() +
      "/getCustomerDetailsByCustomerAdminCode/" +
      code
    );
  }

  /* 
  name: srinivas
  use: return notification details by notification code.
  */
  getHealthcareNotificationByHealthcareNotificationCode(
    notificationCode,
    orgCode,
    callback
  ) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getHealthcareNotificationByHealthcareNotificationCode/" +
        notificationCode +
        "/" +
        orgCode
      )
      .subscribe((data) => {
        callback(data);
      });
  }

  getSettingBySettingName(settingName, orgCode, callback) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getSettingNameByName/" +
        settingName +
        "/" +
        orgCode
      )
      .subscribe((data) => {
        callback(data);
      });
  }

  getTimeZoneByCode(timeZoneCode:string)
  {
    return this.http.get(`${this.service.getHealthCareUrl()}/getTimezoneName/${timeZoneCode}`);
  }

  saveProjectException(formData, callback) {
    return this.http
      .post(this.service.getSuperAdmin() + "/saveProjectException/", formData)
      .subscribe((data) => {
        callback(data);
      });
  }

  saveException(
    componentName,
    exceptionDetail,
    execeptionStatusCode,
    lineNumber,
    serviceUri
  ) {
    try {
      let exceptionData = {
        componentName: componentName,
        exceptionDetail: exceptionDetail,
        execeptionStatusCode: execeptionStatusCode,
        lineNumber: lineNumber,
        serviceUri: serviceUri,
      };

      this.saveProjectException(exceptionData, (response) => {
        if (response.responsecode == 200) {
        }
      });
    } catch (error) { }
  }

  getAllHealthcareNotificationByUserCodeWithUnReadByPage(
    code,
    page,
    limit,
    callback
  ) {
    // console.log(page, limit);
    // return this.http.get(this.service.getNotificationService() + '/getAllNotificationByUserCodeWithUnReadByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
    //   callback(data);
    //   //////console.log(data);
    // })

    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getAllHealthcareNotificationByUserCodeWithUnReadByPage/" +
        code +
        "?page=" +
        page +
        "&limit=" +
        limit
      )
      .subscribe((data) => {
        callback(data);
        //////console.log(data);
      });
  }

  printReport(printTable) {
    var contents = document.getElementById(printTable).innerHTML;
    var frame1 = document.createElement("iframe");
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    var frameDoc = frame1.contentWindow;
    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>DIV Contents</title>");
    frameDoc.document.write("</head><body>");
    frameDoc.document.write(contents);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
  }

  getPermissionByComponentName(componentName: string) {
    // debugger
    if (componentName) {
      if (this.local.get("p_list")) {
        var p_list: any[] = this._cryptoService.performeDecryption(
          this.local.get("p_list")
        );

        const index = p_list.findIndex((element: any) => {
          // debugger
          return element.pagePermissionName === componentName;
        });

        //   const index = this.featureListData.findIndex((element: any) =>
        //   element.pagePermissionName == Current_Component
        //  );

        if (index != -1) {
          return p_list[index];
        } else {
          return {
            // setting default if permission not matched
            addPermission: true,
            delPermission: true,
            editPermission: true,
          };
        }
      }
    }
  }

  // refreshDashboard(data: boolean) {
  //   this.refreshDashboardSubject.next(data);
  // }

  // startRefreshInterval() {
  //   this.refreshInterval = setInterval(() => {
  //     this.refreshDashboard(true);
  //   }, 30000);
  // }

  // stopRefreshInterval() {
  //   clearInterval(this.refreshInterval);
  //   this.refreshDashboardSubject.unsubscribe();
  //   this.refreshDashboardSubject = new Subject();

  // }

  // time format starts

  setEndTimeArray(time: any): any[] {
    let startTime = time.toUpperCase();

    if (startTime) {
      const matches = startTime.match(/ (AM|PM)/g);

      if (matches) {
        // //console.log("Space found before AM or PM.");
        return this.settingEndTime(startTime);
      } else {
        // //console.log("No space found before AM or PM.");
        return this.settingEndTime(startTime.replace(/(AM|PM)/g, " $1"));
      }
    }
  }

  settingEndTime(startTime: string) {
    let s_time: any = startTime.split(" ")[0].split(":")[0];
    s_time = s_time + ":30"; // assigning 30 sec  then the assumed value will be 00:30
    s_time = s_time + " " + startTime.split(" ")[1]; // AM or PM

    // const index = this.time12Hrs.findIndex(ele => ele === startTime);
    const index = this.time12Hrs.findIndex((ele) => ele === s_time); // sothat 's_time'  can filterOut perfectly
    if (index != -1) {
      const temp_array = Array.from(this.time12Hrs);

      const removedItems = temp_array.splice(0, index);

      Array.prototype.push.apply(temp_array, removedItems);

      return temp_array;
    } else {
      return [];
    }
  }

  formateTime(time) {
    if (time) {
      return time.toUpperCase().match(/ (AM|PM)/g)
        ? time.toUpperCase()
        : time.toUpperCase().replace(/(AM|PM)/g, " $1");
    }
  }

  // time formate ends

  getSettingNameByName(settingName: string, orgCode: string) {
    return this.http.get(
      this.service.getHealthCareUrl() +
      "/getSettingNameByName/" +
      settingName +
      "/" +
      orgCode
    );
  }

  getSettingNameByNameWithOperator(settingName: string, orgCode: string) {
    return this.http.get(
      this.service.getHealthCareUrl() +
      "/getSettingNameByNameWithOperator/" +
      settingName +
      "/" +
      orgCode
    );
  }

  verificationToken(queryParams: string, callback: any) {
    // console.log('emailVerificationRespose_0CaLL', queryParams);
    return this.http.get(this.service.getWsmarshalUrl() + "/emailVerificationRespose/" + queryParams).subscribe((data) => {
      callback(data);
    });
  }

  

  /* this method is for getting next 7 days from current date OR By any given date*/

  getSevenDaysByDate(date?: any): any[] 
  {
    const weekdays:ICalendarDate[] = [];

    let currentDate;
    let nonWorkingDaySettingData: any
    this.nonWorkingDayData$.subscribe((data) => {
      if (data) {
        nonWorkingDaySettingData = data;

      }
    });

    if (date) {
      currentDate = moment(date);
    }
    else if (this.Start_of_week) {
      // // Get the current date
      // const currentDate1 = moment();

      // // Calculate the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      // const currentDay = currentDate1.day();

      // // Calculate the day code for Thursday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      // const startDay = this.Start_of_week;

      // // Calculate the number of days to subtract to align with the desired start day
      // const daysToSubtract = (7 + currentDay - startDay) % 7;

      // // Subtract the days and set the time to 00:00:00 to start from the beginning of the day
      // currentDate = currentDate1
      //   .clone()
      //   .subtract(daysToSubtract, "days")
      //   .startOf("week")
      //   .day(startDay);

      // let startOfWeek_date = this.Start_of_week && moment().day(this.Start_of_week).format("MM-DD-YYYY");
      let startOfWeek_date = this.Start_of_week && moment().day(this.Start_of_week).format(this.dateFormat_YYYY_MM_DD);
      const givenDate = startOfWeek_date && moment(startOfWeek_date,this.dateFormat_YYYY_MM_DD);
      const todaysDate = moment();

      if (givenDate <= todaysDate) {
        // currentDate = new Date(startOfWeek_date);
        currentDate = startOfWeek_date && moment(startOfWeek_date,this.dateFormat_YYYY_MM_DD);
      }
      else {
        //if Given date is greater than the current date
        // go one week behind
        currentDate = moment()
          .clone()
          .subtract(1, "week")
          .startOf("week")
          .day(this.Start_of_week);

      }


    }
    else {
      currentDate = moment();
    }


    for (let i = 0; i < 7; i++) {
      const day = currentDate.format("ddd");

      let isWorkingDay = true;
      if (nonWorkingDaySettingData && nonWorkingDaySettingData.length > 0) {
        isWorkingDay = nonWorkingDaySettingData.some(
          (setting) =>
            setting.settingName.toLowerCase() ==
            `Working.Days.${day}`.toLowerCase()
        );
        isWorkingDay=!isWorkingDay
      }
      // console.log("isWorkingDay,day", isWorkingDay,day);
      weekdays.push({
        dateObj: currentDate.toDate(),
        date: currentDate.format("MM-DD-YYYY"),
        currentDate: currentDate.format("MM-DD-YYYY"),
        value: currentDate.format("DD"),
        dayName: currentDate.format("ddd"),
        available: true,
        datemoment: currentDate.clone(),
        yearMonthDateFormat: currentDate.format("YYYY-MM-DD"),
        isWorkingDay: isWorkingDay,
        dataPercent: undefined,
        jobsCount: ""
      });

      // Move to the next day
      currentDate.add(1, "days");
    }

    this.Calendar_7DaysList = weekdays;
    // console.log("getSevenDaysByDate", weekdays);

    this.CardCalendarDays_Strip = weekdays ;

    return weekdays;
  }

  
  getNextSevenDays(): any[] {
    // let temap_arr = Array.from(this.Calendar_7DaysList);

    // let lastInd = temap_arr.reverse()[0]; // getting last index

    //const weekdays = [];
    const first = this.Calendar_7DaysList.at(0);
    // console.log(first); // 👉️ a
    const last = this.Calendar_7DaysList.at(-1);
    // console.log(last); // 👉️ d
    // const currentDate = new Date(last.date);
    // const currentDate = new Date(last.yearMonthDateFormat);

    const currentDate = moment(last.yearMonthDateFormat).toDate();
    currentDate.setDate(currentDate.getDate() + 1);
    let weekdays = this.getSevenDaysByDate(currentDate);

    this.CardCalendarDays_Strip = weekdays ;
    
    return weekdays;
    /* for (let i = 0; i < 7; i++) {
      weekdays.push({
        dateObj: new Date(currentDate),
        date: moment(new Date(currentDate)).format("MM-DD-YYYY"),
        currentDate: moment(new Date(currentDate)).format("MM-DD-YYYY"),
        value: moment(new Date(currentDate)).format("DD"),
        dayName: new Date(currentDate).toLocaleDateString("local", {
          weekday: "short",
        }),
        available: true,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.Calendar_7DaysList = weekdays;

    return weekdays; */
  }

  getPreviousSevenDays() {
    // let temap_arr = Array.from(this.Calendar_7DaysList);

    // let firstInd = temap_arr[0];
    //  const weekdays = [];
    const first = this.Calendar_7DaysList.at(0);
    // console.log(first); // 👉️ a
    const last = this.Calendar_7DaysList.at(-1);
    // console.log(last); // 👉️ d

    // const currentDate = new Date(first.yearMonthDateFormat);
       const currentDate = moment(first.yearMonthDateFormat).toDate();
     
    currentDate.setDate(currentDate.getDate() - 7);
    let weekdays = this.getSevenDaysByDate(currentDate);

    this.CardCalendarDays_Strip = weekdays ;

    return weekdays;
    /*  for (let i = 0; i < 7; i++) {
      weekdays.push({
        dateObj: new Date(currentDate),
        date: moment(new Date(currentDate)).format("MM-DD-YYYY"),
        currentDate: moment(new Date(currentDate)).format("MM-DD-YYYY"),
        value: moment(new Date(currentDate)).format("DD"),
        dayName: new Date(currentDate).toLocaleDateString("local", {
          weekday: "short",
        }),
        available: true,
      });

      currentDate.setDate(currentDate.getDate() - 1);
    }

    this.Calendar_7DaysList = weekdays;

    return weekdays.reverse(); // reverse weekdays; */
  }

  private Start_of_week;
  private Calendar_7DaysList: ICalendarDate[] = [];

  getSevenDaysByStartOfWeek(callback) {
    this.getSettingNameByName(
      "Calendar.View",
      this.local.get("orgCode")
    ).subscribe({
      next: (res: any) => {
        if (res.responsecode == 200) {
          if (res.settingValue === "Start of week") {
            this.getSettingNameByName(
              "Start.Of.Week",
              this.local.get("orgCode")
            ).subscribe({
              next: (res: any) => {
                if (res.responsecode == 200) {
                  this.Start_of_week = res.settingValue;

                  /*   let startOfWeek = moment()
                    .day(this.Start_of_week)
                    .format("MM-DD-YYYY"); */

                  callback(this.getSevenDaysByDate());
                } else {
                  callback(this.getSevenDaysByDate(moment().toDate()));
                }
              },
              error: () => {
                callback(this.getSevenDaysByDate(moment().toDate()));
              },
            });
          } else {
            callback(this.getSevenDaysByDate(moment().toDate()));
          }
        } else {
          callback(this.getSevenDaysByDate(moment().toDate()));
        }
      },
      error: (e) => {
        callback(this.getSevenDaysByDate(moment().toDate()));
      },
    });
  }
  getnonWorkingDayslist() {
    this
      .getSettingNameByNameWithOperator(
        "Working.Days.",
        this.local.get("orgCode")
      )
      .subscribe((res: any) => {
        if (res.responsecode == 200) {

          const nonWorkingDaysData = res.data.filter((setting) => setting.settingValue === 'false');
          this.nonWorkingDayDataSubject.next(nonWorkingDaysData);
        }
      });

  }
  webSocketSubcribeTopic(topicname: any) {
    return this._rxStompService.watch(`/topic/${topicname}`);
  }


  getDayByDayName(dayName: string) {
    let day: any;
    switch (dayName.toLocaleLowerCase()) {
      case "sunday": case "sun":
        day = 0;
        break;

      case "monday": case "mon":
        day = 1;
        break;

      case "tuesday": case "tue":
        day = 2;
        break;

      case "wednesday": case "wed":
        day = 3;
        break;

      case "thursday": case "thu":
        day = 4;
        break;

      case "friday": case "fri":
        day = 5;
        break;

      case "saturday": case "sat":
        day = 6;
        break;

      default:
        day = "no such day exits!";
        break;

    }

    return day;

  }


   getBtnEventPermissions(componentName?) : IBtnControlsPermission
   {
    let tempArray:any[] =[];
    let ppList = this.local.get('MenuListDataAndPagePermission').ppLst  || [];

    ppList.forEach(outer_ele => {
      tempArray.push(outer_ele);

      if(outer_ele.childList.length > 0)
      {
        outer_ele.childList.forEach(inner_ele => {
          tempArray.push(inner_ele);
         });
      }
    });


    let index ;
    
    if(componentName)
    {
     index = tempArray.find(ele => componentName === ele.pagePermissionName);
    }
    else{
     index = tempArray.find(ele => ele.pageUrl === this.route.url );
    }
     

    if(index)
    {
     return {
        "viewPermission": index.viewPermission,
        "addPermission" : index.addPermission,
        "editPermission" : index.editPermission,
        "delPermission" : index.delPermission,
     }
    }
    else
    {
      return {
        "viewPermission": false,
        "addPermission" : false,
        "editPermission" : false,
        "delPermission" : false,
     }
    }


   }


   toTitleCase(str: string) {
    if(str)
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  convertDateFormat_dd_mm_yyyy_to_yyyy_dd_mm(inputDate) {
    // date converter for use in api call in date parameter
    // date formatter for use in api call in date parameter
    // only use for input = as like -> "26-02-2024 12:00 AM"
    // output show/return = as like -> "2024-26-02"
      // Split the input string to get date components
      if(inputDate){
      var parts = inputDate.split(/[ :\-\/]/);
  
      // Extract day, month, year, and AM/PM
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10);
      var year = parseInt(parts[2], 10);
      var ampm = parts[5].toUpperCase();
  
      // Convert AM/PM to 24-hour format
      if (ampm === "PM" && parts[3] !== "12") {
          parts[3] = String(parseInt(parts[3], 10) + 12);
      }
      if (ampm === "AM" && parts[3] === "12") {
          parts[3] = "00";
      }
  
      // Assemble the date string in "yyyy-mm-dd" format
      var formattedDate = year + "-" + ('0' + day).slice(-2) + "-" +('0' + month).slice(-2);
  
      return formattedDate;
    }
  } 

  /* Name:Vivek Chauhan
    use: To Sort All Value included to lower cas as well any case Lower/Uppter or camal*/
    globalSortingDataAccessorForMatSort(item: any, property: string): any {
      // console.log('Sort_ss', item, property);
      // Sorting by string case-insensitively
      const value = item[property];
      return typeof value !== 'number' ? value.toLowerCase() : value;
    }

/* Name:Vivek Chauhan
    use: to open selected user popup by using UserCode, StaffCode or WorkerCode*/
    openPerticularChatByCode(userCode): void {
    //console.log(userCode);
    try {
      this.progressLoader = true;
      this.ChatService.adminGetGroupDetailByUserCodeAndAdminCode(userCode, this.local.get('userCode'), (response) => {
        this.progressLoader = false;
        if (response.responsecode == 200) {
          // console.log('ChatRes: ', response);
    
          const dialogRef = this.dialog.open(ChatComponent, {
            width: '66%',
            panelClass: 'chat-popup',
            data: { groupDetailsCode: response.data.groupDetailsCode},
          });

          dialogRef.afterClosed().subscribe(result => {
            if (this.globalChatCount != 0) {
              try {
                this.adminGetAllUnReadMessageCountByOrganizationCode(userCode, (data) => {
                  this.progressLoader = false;
                  if (data.responsecode == 200) {
                    this.globalChatCount = data.count;
                  } else {
                    this.globalChatCount = 0;
                  }
              })
              } catch (error) {
                //console.log(error)
              }
            }
          });

      }
    })
  } catch (error) {
    //console.log(error)
  }

  }
    

  addUpdateNotes(noteArray = null){
    // console.log('addUpdateNotes: ', true, noteData);
    const dialogRef = this.dialog.open(AddUpdateNoteComponent, { 
      width: '50%',
      disableClose: true,
      panelClass : 'popup-pos',
      data: {noteArray: noteArray}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('AddUpdateNotesModelClose: ', result);
      if(result)
      this.setCommonRefreshEvent(result);
    });
  }

  showGroupNotes(appointmentsProcedureCode = null, date = null, isNotesAdded = null){
    // console.log('showGroupNotes: ', appointmentsProcedureCode, date);
    const dialogRef = this.dialog.open(GroupedNoteListComponent, { 
      width: '85%',
      disableClose: true,
      panelClass : 'popup-pos',
      data: {appointmentsProcedureCode: appointmentsProcedureCode, date: date, isNotesAdded: isNotesAdded}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('AddUpdateNotesModelClose: ', result);
      if(result)
      this.setCommonRefreshEvent(result);
    });
  }

  openRepeatJob(data = null){
    console.log('OpenRepeatJob: ', data);
    const dialogRef = this.dialog.open(RepeatJobComponent, { 
      width: '400px',
      disableClose: true,
      panelClass:'popup-pos',
      data: {data: data}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('AddUpdateNotesModelClose: ', result);
      if(result)
      this.setCommonRefreshEvent(result);
    });
  }

  openStaffDetailPopup(staffCode = null){
    const dialogRef = this.dialog.open(TechnicianEditComponent, {
      width: '95%', height: '100%', position: {right:'0px', top: '0px'},
      disableClose: false,   
      data: {staffCode: staffCode, isStaffDetailOpenInPopup: true}   
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('AddUpdateNotesModelClose: ', result);
      if(result)
      this.setCommonRefreshEvent(result);
    });
  }

  
  openCustomerDetailPopup(customerCode = null){
    const dialogRef = this.dialog.open(PatientComponent, {
      width: '95%', height: '100%', position: {right:'0px', top: '0px'},
      disableClose: false,   
      data: {customerCode: customerCode}   
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('AddUpdateNotesModelClose: ', result);
      if(result)
      this.setCommonRefreshEvent(result);
    });
  }

  resetNotesRequestForm(){
    this.noteRequest = {
      isPublic: true,
      note: null
    } ;
  }

  // Onchange value refresh list in any component from out side of component code Start ------>

  setCommonRefreshEvent(refreshData: any) {  
    this.subjectVal.next(refreshData);
  }
  getCommonRefreshEvent(): Observable<any>{ 
    return this.subjectVal.asObservable();
  }

  // Onchange value refresh list in any component from out side of component code End ------>


autoJobApproval:boolean = false;

isAllowAutoApproval()
{
  this.getSettingBySettingName("Customer.Auto.Job.Approval", this.local.get("orgCode"), (response:any)=>{ 
    if(response.responsecode == 200)
      {
        this.autoJobApproval = response.settingValue && response.settingValue == "true" ? true : false;
      }

  })
}



/*
creating observable to multicast permissions to every component for 
*/
private rolePermission_BS = new Subject<IPagePermissionResponse | null>();
//permissions$ = this.permissionsSubject.asObservable();

get newPermissions(){
  return this.rolePermission_BS.asObservable();
}

 setPermission(data:IPagePermissionResponse)
{
  this.rolePermission_BS.next(data);
}

// check time difference more then 8hrs of not
  isMoreThan8Hours(inTime, outTime) {
    console.log('isMoreThan8Hours', inTime, outTime);
    // Split the input strings into hours and minutes
    const [inHours, inMinutes] = inTime.split(':').map(Number);
    const [outHours, outMinutes] = outTime.split(':').map(Number);

    // Convert both times to minutes since start of the day
    const inTotalMinutes = inHours * 60 + inMinutes;
    const outTotalMinutes = outHours * 60 + outMinutes;

    // Calculate the difference in minutes
    let timeDifference = outTotalMinutes - inTotalMinutes;

    // If the time difference is negative (i.e., the outTime is on the next day), adjust it
    if (timeDifference < 0) {
      timeDifference += 24 * 60; // Add 24 hours worth of minutes
    }

    // Convert the time difference to hours
    const timeDifferenceInHours = timeDifference / 60;
  // console.log('TimeDiff: ', timeDifferenceInHours);
    // Check if the difference is greater than 8 hours
    return timeDifferenceInHours > 8;
  }

  /* Name:Vivek Chauhan
  use: for getting chat list using user Code*/
  getAllChatGroupGlobalChat(isCallFromTopicListener:boolean = null) {
    // console.log('getAllChatGroupGlobalChat: ', response);
    // console.log('allGroupResponse_API: ', this.allGroupResponse);
    try {
      this.ChatService.adminGetAllGroupByOrganizationCode(this.local.get('orgCode'), this.local.get("super_admin_code"), (response) => {
        //console.log(response);
        this.allGroupResponse = response;
        if(isCallFromTopicListener){
          this.getGlobalCountChat();
        }
      }) 
   } catch (error) {
      //console.log(error)
    }
  }
  
  /* Name:Vivek Chauhan
  use: for getting global Chat Count*/
  getGlobalCountChat(){
    // const admin_code = response.data.body.data.userCode;
    const admin_code = this.local.get("super_admin_code");
    this.adminGetAllUnReadMessageCountByOrganizationCode(admin_code, (data: IChatCountResponse) => {
      if (data.responsecode == 200) {
        this.globalChatCount = data.count;
      } else {
        this.globalChatCount = 0;
      }
    })
  }


  /**
   * to get and set customer view limits
   */
 
 setCustomerViewLimits(viewLimits:ICustomerViewLimitations)
{
 
  this.$customer_view_limits.next(viewLimits);
}

 getCustomerViewLimits() : Observable<ICustomerViewLimitations>
{
  return this.$customer_view_limits.asObservable();
}




  getbadgeClass(status: string): string {

    if(!status) return ;

    switch (status.toLowerCase()) {

      case 'on job':
      case 'not submitted':
      case 'pending':
        return 'badge bg-warning';

      case 'accepted':
      case 'approved':
      case 'approve':
        return 'badge bg-success';

      case 'completed':
      case 'submitted':
        return 'badge bg-primary';


      case 'on break':
      case 'absent':
      case 'no show':
      case 'expired':
      case 'expire':
      case 'reject':
      case 'rejected':  
        return 'badge bg-danger';

      default:
        return '';
    }
  }


   convertTo24HourFormat(time: string): string {

    if(!time) return ''

    // Check if input is already in 24-hour format
    if (/^\d{2}:\d{2}$/.test(time)) {
        return time; // Already in 24-hour format
    }

    const [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier.toLocaleLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
    } else if (modifier.toLocaleLowerCase() === 'am' && hours === 12) {
        hours = 0;
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}


 convertTo12HourFormat(input: string): string {

  if(!input) return ;

  // Check if the input is in 24-hour format
  const is24HourFormat = /^(?:[01]?\d|2[0-3]):[0-5]\d$/.test(input);
  
  if (is24HourFormat) {
      // Split hours and minutes
      const [hours, minutes] = input.split(':').map(Number);
      
      // Convert to 12-hour format
      const period = hours >= 12 ? 'PM' : 'AM';
      const adjustedHours = hours % 12 || 12; // Adjust 0 to 12 for 12 AM
      return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  } else {
      // Assuming input is in 12-hour format and valid (with AM/PM)
      return input; // Alternatively, you could validate it further
  }
}


/**
 * get current week dates from input date
 * @param date 
 * @returns 
 */

getWeekDatesByDate(date:moment.Moment) : ICalendarDate[]
{

    const weekdays:ICalendarDate[] = [];
    let nonWorkingDaySettingData: any;

    this.nonWorkingDayData$.subscribe((data) => {
      if (data) {
        nonWorkingDaySettingData = data;

      }
    });

     
    let inputDate : moment.Moment = date ? date : moment() ;

    /**
     * moment().isoWeekday() takes Monday as start day
     * where weekday() takes Sunday as start day
     */
    const startOfWeek = moment(inputDate).clone().isoWeekday(1);


    for (let i = 0; i < 7; i++) 
      {
      const day = startOfWeek.format("ddd");

      let isWorkingDay = true;

      if (nonWorkingDaySettingData && nonWorkingDaySettingData.length > 0) {
        isWorkingDay = nonWorkingDaySettingData.some(
          (setting) =>
            setting.settingName.toLowerCase() ==
            `Working.Days.${day}`.toLowerCase()
        );
        isWorkingDay=!isWorkingDay
      }
       
      weekdays.push({
        dateObj: startOfWeek.toDate(),
        date: startOfWeek.format("MM-DD-YYYY"),
        currentDate: startOfWeek.format("MM-DD-YYYY"),
        value: startOfWeek.format("DD"),
        dayName: startOfWeek.format("ddd"),
        available: true,
        datemoment: startOfWeek.clone(),
        yearMonthDateFormat: startOfWeek.format("YYYY-MM-DD"),
        isWorkingDay: isWorkingDay,
        dataPercent: undefined,
        jobsCount: ""
      });

      // Move to the next day
      startOfWeek.add(1, "days");
    }

    this.Calendar_7DaysList = weekdays;
    this.CardCalendarDays_Strip = weekdays ;

    return weekdays;
  
}


}
