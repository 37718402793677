import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable, Subject } from 'rxjs';
import { PatientService } from './patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
declare var google;
import { MatTableDataSource } from '@angular/material/table';
import { ProviderRegistrationService } from 'src/app/authentication/provider-registration/provider-resgistration.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})

export class PatientComponent implements OnInit, OnDestroy {
  patientForm: FormGroup;
  // siteAddress_Form: FormGroup;
  isSiteForm_submitted :boolean = false;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: [], componentRestrictions: { country: ["in", "us"] } };
  lat: any;
  lng: any;

  tabAtciveFor: string = 'Details';
  phoneCountryCode = this.service.common.CountryCode;
  mobileCountryCode = this.service.common.CountryCode;
  preferredCountryCode = this.service.common.CountryCode;
  patient: any;
  patientCode: any;
  customerCode: any;
  customerAdminCode: any;
  filteredOptions: Observable<any>;
  updatedAddress = '';
  filteredOptionsSkill: Observable<any>;
  @ViewChild('mapDis', { static: true }) mapDis;
  directionsService;
  directionsDisplay;
  map;
  isZoom: boolean = false;
  allClinicianList = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  redirect = false;
  cancelPatient = false;
  isCustomerForm_Submitted : boolean = false;
  image_path : string = '';
  staffSeachInput: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  protected staffList:any[] =[];
  protected staffList_Filter:any[] =[];
  protected customerSiteList:any[] =[];
  protected blockedStaffs:any[] =[];
  protected is_Loading:boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'name', 
    'jobSite',
    'notes', 
    'action'
  ];

  constructor(
    public dialogRef: MatDialogRef<PatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public service: PatientService, public router: Router, public route: ActivatedRoute, public translate: TranslateService,
    private dialog: MatDialog, private fb: FormBuilder, public register: ProviderRegistrationService,
  ) {
    this.translate.setDefaultLang(this.service.local.get('language'));


    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  } 

  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    // console.log('Popup_Data: ', this.data);
    // this.service.resetData();
    this.image_path = this.service.service.getSuperAdmin() + '/downloadFile/';
    this.resetFormData();
    this.service.patientData.mobileCountryCode = this.mobileCountryCode;
    this.service.patientData.phoneCountryCode = this.phoneCountryCode;
    this.service.patientData.preferredCountryCode = this.preferredCountryCode;

    this.service.patientData.organizationCode = this.service.local.get('orgCode');

    this.patientForm.valueChanges.subscribe((val) => {
      this.cancelPatient = true;
    });

    this.getAllStaffMemberByOrgCode();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // //console.log('tabChangeEvent => ', tabChangeEvent?.tab?.textLabel);
    this.tabAtciveFor = tabChangeEvent?.tab?.textLabel; 

    if(tabChangeEvent?.tab?.textLabel.toLocaleLowerCase() === 'staff exceptions')
    {
      if(this.customerCode)
      {
        this.getAllSitesByCustomerCode();
      }
    }
 
  }
 
  getAllSitesByCustomerCode()
  {
    this.service.getCustomerSiteByCustomerCode(this.customerCode).subscribe((response:any) =>{
          
        if (response.responsecode == 200) 
          {
           this.customerSiteList = response.data;
          } 
          else{
            this.customerSiteList = [];
          }
         })
  
  }
 
  /* Name:Shailesh
      use: for seting value to form control*/
  selectedOption(event) {
    ////console.log(event.option.value);
    const index = this.selectedItems.findIndex(fruit => fruit.itemName === event.option.value.itemName);
    ////console.log(index);
    if (index == -1) {
      this.patientForm.controls['excludeClinician'].setValue(event.option.value);
      this.dropdownList.push(event.option.value.workerCode);
      this.selectedItems.push(event.option.value);
      this.patientForm.controls['clinicianSelect'].setValue(null);
    } else {
      this.service.common.message = event.option.value.itemName + ' is already selected';
      this.service.common.errorMessage = true;
    }

  }

 
  /* Name:Shailesh
      use: for searching clinician name*/
  private _filter(name) {
    ////console.log(name);
    if (name) {
      const filterValue = name;
      return this.allClinicianList.filter(option => option.itemName.toLowerCase().includes(name.toLowerCase()));

    } else {
      ////console.log(name);
      return this.allClinicianList;
    }

  }

  /* Name:Shailesh
      use: to set prefferred number*/
  ispreferredNumber(event) {
   if (event.checked && this.patientForm.value.phoneNumber) {
      this.patientForm.controls['preferredNumber'].setValue(this.patientForm.value.phoneNumber);
    } else {
      this.patientForm.controls['preferredNumber'].setValue(null);
    }
  }

  /* Name:Shailesh
      use: to reset all form data */
  resetFormData() {
    this.patientDataReset();
    this.patientForm = this.fb.group({
       'customerCode' : new FormControl(null),
       'customerLogo' : new FormControl(''),
       'patientName': new FormControl('', Validators.required),
      'patientEmail': new FormControl(null, [Validators.required, Validators.email], this.confirmEmailAvailability.bind(this)),
      'phoneNumber': new FormControl(''),
      'mobileNumber': new FormControl(''),
      'preferredNumber': new FormControl(''),
      'mobileCountryCode': new FormControl(''),
    
      'mainPhone': new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      'mainFax': new FormControl(''),
      'primaryContact': new FormControl(''),
      'primaryContactPhone': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      'customerAddress': new FormControl(''),
      'customerLocation': new FormControl(''),
      'customerState': new FormControl(''),
      'customerCity': new FormControl(''),
      'zipCode': new FormControl(''),

       // billing field
      'billingEmail': new FormControl(null, [Validators.required, Validators.email], this.confirmEmailAvailability.bind(this)),
      'billingPhone': new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      'billingContact': new FormControl(''),
      'billingContactPhone': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      'billingAddress': new FormControl(''),
      'billingLocation': new FormControl(''),
      'billingTax': new FormControl('',),
      'billingState': new FormControl(''),
      'billingCity': new FormControl(''),
      'billingZipCode': new FormControl(''),
      
      // staff exceptions controls
      'exc_staff': new FormControl(''),
      'exc_jobSite': new FormControl([]),
      'excpNotes': new FormControl('')
  });
 
    this.route.queryParams.subscribe((data) => {
      let customerCode = data.patientCode || this.data.customerCode;
      if (customerCode) {        
        // //console.log('queryParams: ', data);
        this.patientCode = customerCode;
        this.customerCode = customerCode;
        this.service.patientData.patientCode = this.patientCode;       
        this.getCustomerDetailByCustomerCode(customerCode);
        this.getAllBlockedStaff();

        setTimeout(() => {
          this.cancelPatient = false;
        }, 1000);

      } else if (data.appointmentRedirect) {
        this.redirect = true;
      } else {
        //this.initialize();
      }
    });
  }

  getCustomerDetailByCustomerCode(customerCode: string){
    try {
      if(this.data?.customerCode){ this.service.common.progressLoader = true; }      
      // this.service.getPatientDetailByPatientCode(this.patientCode, (response) => {
      this.service.getCustomerDetailByCustomerCode(customerCode, (response) => {
        this.service.common.progressLoader = false;
        if (response.responsecode == 200) 
        {
          
          this.service.patientData = response;
          this.service.customerData = response.data;

          /*old code as per old form design ---- starts*/
          this.patientForm.controls['patientName'].setValue(this.service.customerData.customerName);
          this.patientForm.controls['patientEmail'].setValue(this.service.customerData.customerEmail);
          this.patientForm.controls['phoneNumber'].setValue(this.service.customerData.homePhone);
          this.patientForm.controls['mobileNumber'].setValue(this.service.customerData.mobilePhone);
          this.patientForm.controls['preferredNumber'].setValue(this.service.customerData.workPhone);
          this.mobileCountryCode = this.service.customerData.mCountryCode;
          this.getCountryCode(response.data.mCountryCode, 'mobileCountryCode');
         /*old code as per old design ---- ends*/


         /*new code as per new form design starts*/
         this.customerSiteList = response.data.customerAddressResponseList || [] ;
         console.log("this.customerSiteList",this.customerSiteList)
         this.BindCustFormValues(response.data);
          /*new code as per new form design ends*/

        
          // this.patientForm.controls['address'].setValue(response.data.location);
          this.service.patientData.location = response.data.location;
   
          this.service.patientData.patientAddress = response.data.patientAddress;
          //+','+response.data.patientCity+','+response.data.patientState+' '+response.data.zipCode+','+response.data.patientCountry
          this.phoneCountryCode = this.service.patientData.phoneCountryCode;
          // this.patientForm.controls['phoneCountryCode'].setValue(this.phoneCountryCode);

          this.mobileCountryCode = this.service.patientData.mobileCountryCode;
          this.patientForm.controls['mobileCountryCode'].setValue(this.mobileCountryCode);

          this.preferredCountryCode = this.service.patientData.preferredCountryCode;
          // this.patientForm.controls['preferredCountryCode'].setValue(this.preferredCountryCode);

          // if (this.service.patientData.patientDocumentMappingReqList.length > 0) {
          //   this.patientForm.controls['patientDocuments'].setValue(JSON.stringify(this.service.patientData.patientDocumentMappingReqList));
          // }
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }



  /* Name:Shailesh
        use: for getting lattitude and longitude*/
  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();
    let newLatLang = new google.maps.LatLng(response.data.patientLatitude, response.data.patientLongitude);

    geocoder.geocode({ 'latLng': newLatLang }, function (results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }


  /* Name:Shailesh
     use: to set country code*/
  getNumber(event, countryCode) {
    this.patientForm.controls[countryCode].setValue(event.dialCode);
    this.service.patientData[countryCode] = event.dialCode;
  }

 
  /* Name:Shailesh
     use: for address validation*/
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>(
      (resolve, reject) => {
        let addr = control.value;
        // Get geocoder instance
        let geocoder = new google.maps.Geocoder();
        // Geocode the address
        // ////console.log(control.value);
        geocoder.geocode({ 'address': addr }, function (results, status) {
          ////console.log(results);
          ////console.log(status);
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            ////console.log(addr, results[0].formatted_address)
            if (addr === results[0].formatted_address) {
              resolve(null);

            } else {
              resolve({
                invalid: true
              });
            }
            // show an error if it's not
          } else {
            //////console.log('invalid');
            resolve({
              invalid: true
            });
          }
        });
      });
    return addressValidation;
  }

 

  /* Name:Shailesh
     use: to set country code*/
  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    this.patientForm.controls[formControlName].setValue(event);
    this.service.patientData[formControlName] = event;
  }

  /* Name:Shailesh
     use: for cancel popup*/
  cancelPopup() {
    if (this.cancelPatient) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.service.common.resetNotesRequestForm();
          // this.resetFormData();
          // this.router.navigate(['/dashboard/patient/patient-list']);
          this.router.navigate(['/dashboard/customer/customer-list']);
        }
      });
    } else {
      this.service.common.resetNotesRequestForm();
      // this.router.navigate(['/dashboard/patient/patient-list']);
      this.router.navigate(['/dashboard/customer/customer-list']);
    }
  }

  patientDataReset() {
    this.service.patientData = {
      'patientIcon': '',
      'location': '',
      'patientName': '',
      'patientId': '',
      'phoneNumber': '',
      'mobileNumber': '',
      'patientAddress': '',
      'patientHistoryDescription': '',
      'recurringNotes': '',
      'patientDocumentMappingReqList': [],
      'patientLatitude': '',
      'patientLongitude': '',
      'patientCountry': '',
      'patientCity': '',
      'zipCode': '',
      'patientState': '',
      "isActive": true,
      'mobileCountryCode': '',
      "organizationCode": "",
      "patientEmail": "",
      'phoneCountryCode': '',
      'patientCode': '',
      'address': '',
      'patientApartmentNo': '',
      'areaReferDoctor': '',
      'caretakerNumber': '',
      'caretakerCountryCode': '',
      'preferredNumber': '',
      'preferredCountryCode': '',
      'isPreferSameClinician': true,
      'excludeClinician': '',
      'experience': 0,
      'minimumProficiency': 0,
      'preferredPhoneType': ''
    };
    this.service.customerData = {
      'customerCode': '',
      'customerName': '',
      'organizationCode': '',
      'homePhone': '',
      'mobilePhone': '',
      'workPhone': '',
      'mCountryCode': '',
      'customerEmail': '',
      'customerAddressResponseList': []
    }
  }


  CustomerAddressChange(address: Address){
  
    let route = this.extractAddressComponent(address, 'route') ;
    let sublocality_level_2 = this.extractAddressComponent(address, 'sublocality_level_2');
    let sublocality_level_1 = this.extractAddressComponent(address, 'sublocality_level_1')

    this.patientForm.controls.customerAddress.setValue(route+' '+sublocality_level_2+' '+sublocality_level_1);
    // this.patientForm.controls.customerAddress.setValue(this.extractAddressComponent(address, 'route'));
    this.patientForm.controls.customerCity.setValue(this.extractAddressComponent(address, 'locality'));
   // this.patientForm.controls.customerCountry.setValue(this.extractAddressComponent(address, 'country'));
    this.patientForm.controls.customerState.setValue(this.extractAddressComponent(address, 'administrative_area_level_1'));
    this.patientForm.controls.customerLocation.setValue(address.formatted_address);
    this.patientForm.controls.zipCode.setValue(this.extractAddressComponent(address, 'postal_code'));
    
  }

 
  extractAddressComponent(place: Address, componentType: string) {
    const component = place.address_components.find(comp =>
      comp.types.some(type => type === componentType)
    );
    return component ? component.long_name : '';
  }


  BillingAddressChange(address: Address){
    let route = this.extractAddressComponent(address, 'route') ;
    let sublocality_level_2 = this.extractAddressComponent(address, 'sublocality_level_2');
    let sublocality_level_1 = this.extractAddressComponent(address, 'sublocality_level_1')

    this.patientForm.controls.billingAddress.setValue(route+' '+sublocality_level_2+' '+sublocality_level_1);
    // this.patientForm.controls.billingAddress.setValue(this.extractAddressComponent(address, 'route'));
    this.patientForm.controls.billingCity.setValue(this.extractAddressComponent(address, 'locality'));
   // this.patientForm.controls.customerCountry.setValue(this.extractAddressComponent(address, 'country'));
    this.patientForm.controls.billingState.setValue(this.extractAddressComponent(address, 'administrative_area_level_1'));
    this.patientForm.controls.billingLocation.setValue(address.formatted_address);
    this.patientForm.controls.billingZipCode.setValue(this.extractAddressComponent(address, 'postal_code'));
  }


  saveCustomerDetails(isMail: boolean){
    this.isCustomerForm_Submitted = true ;

    if(this.patientForm.valid){

      let json_req :any = {
        "organizationCode": this.service.local.get('orgCode'),
        "customerCode": this.patientForm.controls.customerCode.value,
       
        "alternatePhone": "",
        "billingAddress": this.patientForm.controls.billingAddress.value,
        "billingCity": this.patientForm.controls.billingCity.value,
        "billingContact": this.patientForm.controls.billingContact.value,
        "billingContactPhone": this.patientForm.controls.billingContactPhone.value,
        "billingEmail": this.patientForm.controls.billingEmail.value,
        "billingLocation": this.patientForm.controls.billingLocation.value,
        "tax": this.patientForm.controls.billingTax.value,
        "billingPhone": this.patientForm.controls.billingPhone.value,
        "billingState": this.patientForm.controls.billingState.value,
        "billingStreet1": "",
        "billingStreet2": "",
        "billingZipCode": this.patientForm.controls.billingZipCode.value,
        "customerAddress": this.patientForm.controls.customerAddress.value,
        "customerCity": this.patientForm.controls.customerCity.value,
         "customerEmail": this.patientForm.controls.patientEmail.value,
        "customerLocation": this.patientForm.controls.customerLocation.value,
        "customerName": this.patientForm.controls.patientName.value,
        "customerState": this.patientForm.controls.customerState.value,
        "customerStreet1": "",
        "customerStreet2": "",
        "customerType": "",
        "mainFax": this.patientForm.controls.mainFax.value,
        "mainPhone": this.patientForm.controls.mainPhone.value,
        "primaryContact": this.patientForm.controls.primaryContact.value,
        "primaryContactPhone": this.patientForm.controls.primaryContactPhone.value,
        "zipCode": this.patientForm.controls.zipCode.value,
        "isMail": !this.patientForm.controls.customerCode.value && isMail ? true : null,
        "noteRequest": this.service?.common?.noteRequest ? this.service.common.noteRequest : null
      }

  //console.log(json_req);
   this.service.common.progressLoader = true;
      this.service.Save_OR_UpdateCustomer(json_req).subscribe((response:any) =>{
        this.isCustomerForm_Submitted = true ;
              if (response.responsecode == 200) {
                this.getCustomerDetailByCustomerCode(response.data.customerCode);
                this.service.common.resetNotesRequestForm();
                this.patientCode = response.data.customerCode;
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
              //this.patientForm.reset();
              this.patientForm.controls.customerCode.setValue(response.data.customerCode);
              // this.router.navigate(['/dashboard/customer/customer-list']);              
              if(response?.data?.customerCode){
                this.customerCode = response.data.customerCode
                this.router.navigate(['/dashboard/customer/customer-add'], { queryParams: { patientCode: response.data.customerCode } });
              }
              } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
              }
                     
      })

    }
  }

  BindCustFormValues(data:any){
    //console.log('BindCustFormValues', data)

    this.patientForm.controls['customerCode'].setValue(data.customerCode);
   
     this.patientForm.controls['patientName'].setValue(data.customerName);
    this.patientForm.controls['patientEmail'].setValue(data.customerEmail);
    this.patientForm.controls['customerLogo'].setValue(data.customerLogo ? data.customerLogo : '');

    this.patientForm.controls["billingAddress"].setValue(data.billingAddress);  
    this.patientForm.controls["billingCity"].setValue(data.billingCity);  
    this.patientForm.controls["billingContact"].setValue(data.billingContact);  
    this.patientForm.controls["billingContactPhone"].setValue(data.billingContactPhone);  
    this.patientForm.controls["billingEmail"].setValue(data.billingEmail);  
    this.patientForm.controls["billingLocation"].setValue(data.billingLocation);  
    this.patientForm.controls["billingTax"].setValue(data.tax); 
    this.patientForm.controls["billingPhone"].setValue(data.billingPhone);  
    this.patientForm.controls["billingState"].setValue(data.billingState);  
    this.patientForm.controls["billingZipCode"].setValue(data.billingZipCode);  
    this.patientForm.controls["customerAddress"].setValue(data.customerAddress);  
    this.patientForm.controls["customerCity"].setValue(data.customerCity); 
    this.patientForm.controls["customerLocation"].setValue(data.customerLocation);
    this.patientForm.controls["customerState"].setValue(data.customerState); 
    this.patientForm.controls["mainFax"].setValue(data.mainFax); 
    this.patientForm.controls["mainPhone"].setValue(data.mainPhone); 
    this.patientForm.controls["primaryContact"].setValue(data.primaryContact); 
    this.patientForm.controls["primaryContactPhone"].setValue(data.primaryContactPhone); 
    this.patientForm.controls["zipCode"].setValue(data.zipCode);  

  }
 

  handle_SameAsAbove(event)
  {

      if(event.target.checked)
      {
      this.patientForm.controls['billingEmail'].setValue(
      this.patientForm.controls['patientEmail'].value
      );

      this.patientForm.controls['billingPhone'].setValue(
      this.patientForm.controls['mainPhone'].value
      );

      this.patientForm.controls['billingContact'].setValue(
      this.patientForm.controls['primaryContact'].value
      );

      this.patientForm.controls['billingContactPhone'].setValue(
      this.patientForm.controls['primaryContactPhone'].value
      );

      this.patientForm.controls['billingLocation'].setValue(
      this.patientForm.controls['customerLocation'].value
      );

      this.patientForm.controls['billingState'].setValue(
      this.patientForm.controls['customerState'].value
      );

      this.patientForm.controls['billingCity'].setValue(
      this.patientForm.controls['customerCity'].value
      );

      this.patientForm.controls['billingZipCode'].setValue(
        this.patientForm.controls['zipCode'].value
        );


      }
      else
      {
      this.patientForm.controls['billingEmail'].setValue('');

      this.patientForm.controls['billingPhone'].setValue(" "); // keep space (considering as empty field)
      this.patientForm.controls['billingContactPhone'].setValue(" "); // keep space (considering as empty field)

      this.patientForm.controls['billingContact'].setValue('');
      this.patientForm.controls['billingLocation'].setValue('');

      this.patientForm.controls['billingState'].setValue('');

      this.patientForm.controls['billingCity'].setValue('' );

      this.patientForm.controls['billingZipCode'].setValue('');
      }

  }

  formValueChangedSubs()
  {
    this.staffSeachInput.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCustomer();
    });
  }

  private filterCustomer() {
    if (this.staffList.length == 0) {
      return;
    }
    let search = this.staffSeachInput.value.trim();

    this.staffList_Filter = this.staffList.filter((ele) =>
      ele.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  getAllStaffMemberByOrgCode()
  {
    this.service.getAllStaffMemberByOrgCode(this.service.local.get('orgCode')).subscribe((response:any) =>{
        
          if (response.responsecode == 200) 
            {
             this.staffList = response.data;
             this.staffList_Filter = response.data;
             this.formValueChangedSubs();
            // console.log(" this.staffList",  this.staffList)
            } 
          
                   
    })
  }

  addStaffInBlockList()
  {

    if(this.patientForm.controls.exc_staff.value.staffCode && this.patientForm.controls.exc_jobSite.value && this.patientForm.controls.exc_jobSite.value?.length > 0) {
      // Do nothing
    } else {
      this.service.common.message = 'Please select staff and job site for block';
      this.service.common.errorMessage = true;
    }
    // console.log('addStaffInBlockList_0: ', this.patientForm.controls.exc_staff.value, this.patientForm.controls.exc_jobSite.value);
 
    if(this.patientForm.controls.exc_staff.value && this.patientForm.controls.exc_jobSite.value && this.patientForm.controls.exc_jobSite.value?.length > 0)
    {
      let customerAddressCodeList :any[] =[];
 
      if(this.patientForm.controls.exc_jobSite.value?.length === this.customerSiteList.length)
      {
       customerAddressCodeList.push('All');
      }
      else{
       customerAddressCodeList = this.patientForm.controls.exc_jobSite.value.map(ele=>{return ele.customerAddressCode});
      }

      
       let requestBody = {
        "customerAddressCodeList":customerAddressCodeList ,
        "customerCode": this.customerCode,
        "staffCode": this.patientForm.controls.exc_staff.value.staffCode,
        "note" : this.patientForm.controls.excpNotes.value
      }
      // console.log('addStaffInBlockList_1: ', requestBody);
      // return;
      this.service.common.progressLoader = true;
      this.service.saveBlockedStaffs(requestBody).subscribe((response:any) =>{
        this.service.common.progressLoader = false;

          this.service.common.message = response.message;
          if (response.responsecode == 200) 
            {
              this.service.common.successMessage = true;
              this.getAllBlockedStaff();
              this.patientForm.controls.exc_staff.setValue("");
              this.patientForm.controls.exc_jobSite.setValue([]);
              this.patientForm.controls.excpNotes.setValue("");
            } 
            else {
                 this.service.common.errorMessage = true;
              }

     })

    }
    

  }

  getAllBlockedStaff()
  {
    this.service.getAllBlockedStaffByCustomerCode(this.customerCode).subscribe((response:any) =>{
        
      if (response.responsecode == 200) 
        {
         this.blockedStaffs = response.data;
         
        } 
        else
        {
          this.blockedStaffs = [] ;
        }
        this.dataSource = new MatTableDataSource(this.blockedStaffs);
       })
  }


  removeStaffFromBlockList(element)
  {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: 'Are you sure want to remove this staff from list?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
      {
        this.service.common.progressLoader = true;
        this.service.removeStaffFromBlockList(element.blockedCustomerStaffCode).subscribe((response:any) =>{
          this.service.common.progressLoader = false;

          this.service.common.message = response.message;
          if (response.responsecode == 200) 
            {
              this.service.common.successMessage = true;
              this.getAllBlockedStaff();
            } 
            else {
                 this.service.common.errorMessage = true;
              }
                
           })
      }
    });
  }

  onJobSiteChange()
  {
    if(this.patientForm.controls.exc_jobSite.value.find(ele=>ele === 'All'))
       {
        this.customerSiteList.map(ele => ele.isDisable = true);
       }
       else{
        this.customerSiteList.map(ele => ele.isDisable = false)
       }
  }

  confirmEmailAvailability(control: FormControl): Promise<any> | Observable<any> {
    const emailavaiable = new Promise<any>(
      (resolve, reject) => {

        this.register.checkEmailAvialablity(control.value, (response) => {
          //////console.log(response);
          if (response == false) {

            if(this.customerCode){
              resolve(null);
            } else {
              resolve({ invalid: true });              
            }            
          } else {
              resolve(null);
          }
        });
      });
    return emailavaiable;
  }

  closeDialog(){
    this.dialogRef.close();
  }

}

