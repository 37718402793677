<div class="st-jobTypeDts-main-wrapper">

    <ng-template [ngIf]="shjobTypeDtsInput">
    <div class="row">

        <div class="col-1">
          <div class="row">
            <div class="card-jobdate" [style.color]="shjobTypeDtsInput.jobTypeColor">
              <span>{{shjobTypeDtsInput?.week_day}}</span>
              <span class="month-day">{{shjobTypeDtsInput?.month_day}}</span>
              <span>{{shjobTypeDtsInput?.month_name}}</span>
            </div>
          </div>
        </div>

        <ng-template [ngIf]="shjobTypeDtsInput?.customerName">
        <div class="col">
           <small>Customer Name </small><br>
                <p>{{shjobTypeDtsInput?.customerName}}</p>
        </div>
      </ng-template>

        <div class="col">
           <small>{{ 'main.labels.Job Site' | translate }} </small><br>
            <p [title]="shjobTypeDtsInput.siteAddress ? shjobTypeDtsInput.siteAddress : shjobTypeDtsInput.siteName"> {{shjobTypeDtsInput?.siteName}}</p>
         </div>
 
        <div class="col">
          <div class="row">
            <div class="col-3 p-0 m_width_50">
              <div class="w-100">
                <img class="img_jobtype"
                  [src]="shjobTypeDtsInput.jobTypeIcon ? img_serverPath+shjobTypeDtsInput.jobTypeIcon : 'assets/common_images/profile.jpg'"
                  onerror="this.src='assets/common_images/profile.jpg';">
              </div>
            </div>
             <div class="col  ps-2">
               <small>Job Type </small><br>
                <p>{{shjobTypeDtsInput?.jobTypeName}}</p>
             </div>
          </div>
        </div>

        <ng-template [ngIf]="shjobTypeDtsInput.shift">
          <div class="col">
               <small>Shift </small><br>
              <p>{{shjobTypeDtsInput?.shift}}</p>
           </div>
        </ng-template>

        <ng-template [ngIf]="userType != 'Customer'"> 
        <ng-template [ngIf]="shjobTypeDtsInput.rateCard">
          <div class="col">
              <small>Billing Rate/Hr </small><br>
              <p> $&nbsp;{{shjobTypeDtsInput?.rateCard}}</p>
           </div>
        </ng-template>
        </ng-template>
    
      </div>
    </ng-template>

    <!-- <ng-content select="[shiftDetails]"></ng-content> -->

</div>