import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakForm } from 'src/app/shared/interface/core.interface';
 

 
@Component({
  selector: 'app-break-form',
  templateUrl: './break-form.component.html',
  styleUrls: ['./break-form.component.scss']
})
export class BreakFormComponent  implements OnInit{

  // breakType : FormControl = new FormControl();
  // paidStatus : FormControl  = new FormControl();
  // duration : FormControl  = new FormControl();

  parentBreakForm : FormGroup ;
  @Input() formData : BreakForm[] ;
  @Output() getFormData = new EventEmitter();
  @Output() noBreaks = new EventEmitter();
  protected is_formSubmitted : boolean = false ;

  constructor(
    private fb : FormBuilder,
    private matDialogRef: MatDialogRef<BreakFormComponent>)
  {
   this.initializeForm();
   //this.vi();
  }

  ngOnInit(): void {
     if(this.formData.length > 0)
      {
        this.formData.forEach((ele:BreakForm)=>{
         // this.bindBreakForm(ele)
         this.addBreak(ele)
        })
         
      }
      else{
        this.addBreak();
      }
  }

  initializeForm()
  {
    this.parentBreakForm = this.fb.group({
      breakForm : this.fb.array([])
    })
  }


  get getBreakForm()
  {
    return this.parentBreakForm.get("breakForm") as FormArray
  }

  addBreak(data?:BreakForm): void 
  {
    // if(data && Object.keys(data).length > 0)
    //   {
    //     this.bindBreakForm(data) ;
    //   }
    //   else
    //   {

        const breakFormGroup = this.fb.group({
          breakType: [data && Object.keys(data).length > 0 ? data.breakType : '', Validators.required],
          paidStatus: [
            data && Object.keys(data).length > 0 
            ? (data.paidStatus === true || (typeof data.paidStatus === 'string' && data.paidStatus.toLowerCase() === 'paid')) ? true : false
            : false
          ],
          duration: [data && Object.keys(data).length > 0 ? data.duration : '', [Validators.required, Validators.pattern("^[1-9][0-9]?$|^100$")]]
        });
    
        this.getBreakForm.push(breakFormGroup);
      //}
 
  }

  removeBreak(index: number): void 
  {
    this.getBreakForm.removeAt(index);
    this.validate_and_emmit();

    if( this.getBreakForm.length === 0){
      this.noBreaks.emit([]);
    }
  }

  bindBreakForm(data:BreakForm)
  {
    const breakFormGroup = this.fb.group({
      breakType: [data.breakType, Validators.required],
      paidStatus: [data.paidStatus],
      duration: [data.duration, Validators.required, Validators.pattern("^[1-9][0-9]?$|^100$")]
    });

    this.getBreakForm.push(breakFormGroup);
  }


  onChxChange(index:number)
  {
    this.validate_and_emmit();
  }


  // validateForm()
  validate_and_emmit()
  {
    this.is_formSubmitted = true ;

    if(this.parentBreakForm.valid)
      {
        this.getFormData.emit(this.getBreakForm.value);

        /* if opening through modal popup
         create modalRef instance in parent Component (give unique name)
         and access that modalRef here.
         and use modalRef.close() */

       // this.matDialogRef.close();
      }
  }


  // vi()
  // {
  //   this.parentBreakForm.valueChanges.pipe().subscribe((res:any) =>{
  //     if(res)
  //     console.log("form value changes", res);
  //   })
  // }


  // validate_and_emmit()
  // {

  // }


}
