import { Component, Input, OnInit } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { cust_st_jt_dts } from 'src/app/shared/interface/core.interface';


@Component({
  selector: 'app-cust-short-job-type-dts',
  templateUrl: './cust-short-job-type-dts.component.html',
  styleUrls: ['./cust-short-job-type-dts.component.scss']
})
export class CustShortJobTypeDtsComponent implements OnInit {

  @Input() shjobTypeDtsInput : cust_st_jt_dts ;
  img_serverPath:string = "";
  userType : string = '' ;

  constructor(
    private _apiSVC: ApiRootService,
  private sessionService : SessionStorageService)
  {}

 ngOnInit(): void 
 {
  this.userType = this.sessionService.get("user_type");
  this.img_serverPath = this._apiSVC.getSuperAdmin() + '/downloadFile/';

 
  if(this.shjobTypeDtsInput && this.shjobTypeDtsInput.date)
    {
       
      this.shjobTypeDtsInput.week_day = moment(this.shjobTypeDtsInput.date).format('ddd');
      this.shjobTypeDtsInput.month_day = moment(this.shjobTypeDtsInput.date).format('D');
      this.shjobTypeDtsInput.month_name = moment(this.shjobTypeDtsInput.date).format('MMM');
    
       
    }

 }
}
