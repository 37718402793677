<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Note <span *ngIf="data?.noteTitleOpenFor">for {{data.noteTitleOpenFor | capitalize}}</span></h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">      
        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
         <textarea class="noteInput" id="note_input_1" matInput rows="4" [(ngModel)]="notes"></textarea>
        </mat-form-field>
    </div>
  </div>

  <div class="row mt-1">    
    <div class="col-lg-12 text-end">
      <div class="checkboxLft">
        <mat-checkbox class="mat-option" [checked]="!noteRequest.isPublic" (change)="toggleSelection($event)" color="primary">Is Private</mat-checkbox>
      </div>
      <button class="me-3" mat-button mat-dialog-close>Cancel</button>
      <button *ngIf="isNoteEdit" mat-raised-button color="primary" (click)="updateNote(seletedNoteForEdit)">Update</button>
      <button *ngIf="!isNoteEdit" mat-raised-button color="primary" (click)="saveNote()">Add</button>
    </div>
  </div>
  <div *ngIf="data?.noteArray?.length > 0" class="row mt-3 chatScroll">
    <!-- threaded-note-list Component Use for show note list in Treaded view -->
    <threaded-note-list [noteArray]="data.noteArray" (noteChangeEvent)="noteChangeEvent($event)"></threaded-note-list>
  </div>
</div>
