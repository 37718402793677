import { Pipe, PipeTransform } from "@angular/core";
 
@Pipe({
    name : 'mobileNoFormate'
})

export class MobileNumberFormatorePipe implements PipeTransform {

    transform(mob_no: string, ...args: any[]) {
        if (!mob_no) return;

       const val:string =  mob_no.replace(/[_-\s]/g, ''); 

       return val.length > 10 ? `+${mob_no}` : mob_no ;

    }

}