import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { MyNavComponent } from './my-nav/my-nav.component';
import { CommonComponent } from './common.component';
// import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MessagesComponent } from '../messages/messages.component';
import { NotificationMsgComponent } from '../messages/notification-msg/notification-msg.component';
import { ConfirmLogoutComponent } from './confirm-logout/confirm-logout.component';
import { NotesPopupComponent } from '../dashboards/providers-dashboard/appointment/notes-popup/notes-popup.component';
// import { ProvidersDashboardModule } from '../dashboards/providers-dashboard/providers-dashboard.module';
// import { TechnicianDashboardModule } from './../dashboards/technician-dashboard/technician-dashboard.module';
import { ShortNotificationComponent } from './short-notification/short-notification.component';

@NgModule({
  declarations: [
    CommonComponent,
    HeaderComponent,
    MyNavComponent,
    Layout1Component,
    Layout2Component,
    // UnauthorizedComponent,
    MessagesComponent,
    NotificationMsgComponent,
    ConfirmLogoutComponent,
    NotesPopupComponent,
    ShortNotificationComponent,
  ],
  imports: [
    SharedModule,
    // ProvidersDashboardModule,
    // TechnicianDashboardModule
  ],
  exports: [
    CommonComponent,
    HeaderComponent,
    MyNavComponent,
    Layout1Component,
    Layout2Component,
    // UnauthorizedComponent,
    NotificationMsgComponent,
    NotesPopupComponent,
    ShortNotificationComponent
  ],
  providers: [],
  entryComponents:[NotificationMsgComponent, ConfirmLogoutComponent, NotesPopupComponent]
})
export class CommonModule { }
