import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { AngularWebStorageModule } from 'angular-web-storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ScrollingModule } from '@angular/cdk/scrolling';
//import { NgxFloatButtonModule } from 'ngx-float-button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CountryCodeComponent } from '../common/country-code/country-code.component';
import { RejectAppointmentComponent } from '../dashboards/technician-dashboard/new-appointment/reject-appointment/reject-appointment.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HighlightDirective } from './highlight.directive';
import { ProcedureDetailComponent } from '../dashboards/providers-dashboard/appointment/procedure-detail/procedure-detail.component';
import { NotificationComponent } from '../dashboards/providers-dashboard/notification/notification.component';
import { UnauthorizedComponent } from '../common/unauthorized/unauthorized.component';
import { SiteMaintenanceComponent } from '../common/unauthorized/site-maintenance/site-maintenance.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ExportAsModule } from 'ngx-export-as';
import { SearchPipeFilter, CapitalizePipe } from 'src/app/common/pipe-filter/pipe-filter.component';
import { StaffViewComponent } from "../dashboards/providers-dashboard/appointment/staff-view/staff-view.component";
import { JobViewComponent } from "../dashboards/providers-dashboard/appointment/job-view/job-view.component";
import { UserRegistrationComponent } from 'src/app/dashboards/providers-dashboard/user-registration/user-registration.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CardViewComponentComponent } from "../components/card-view-component/card-view-component.component";
import { ActivityLogComponent } from "../components/activity-log/activity-log.component"
import { DistributionListComponent } from "../components/distribution-list/distribution-list.component";
import { FailedStaffListPopup } from "../components/failed-staff-list/failed-staff-list.component";
import { AddDistributionComponent } from "../components/distribution-list/add-distribution/add-distribution.component";
import { WeeklyCalendarComponent } from "../components/WeeklyCalendar-component/WeeklyCalendar-component";
import { AddTeamSiteComponent } from "../components/add-team-site/add-team-site.component";
import { AddDistributionInStaff } from "../components/add-distribution-in-staff/add-distribution-in-staff.component";
import { AssignedDistributionListForStaff } from "../components/assigned-distribution-list-for-staff/assigned-distribution-list-for-staff.component";
import { AssignedDistributionListPopup} from "../components/assigned-distribution-list-popup/assigned-distribution-list-popup.component";
import { ClockedOutPopUp } from '../components/clocked-out-pop-up/clocked-out-pop-up.component';
import { CurrentDateTimeComponent } from 'src/app/components/current-date-time/current-date-time.component';
import { CommonDataListViewComponent } from "../components/common-data-list-view/common-data-list-view.component";
import { AddUpdateNoteComponent } from '../components/common-notes/add-update-note/add-update-note.component';
import { NotesListComponent } from '../components/common-notes/notes-list/notes-list.component';
import { ThreadedNoteListComponent } from '../components/common-notes/threaded-note-list/threaded-note-list.component';
import { GroupedNoteListComponent } from '../components/common-notes/grouped-note-list/grouped-note-list.component';
import { TeamManagementComponent } from "../components/team-management/team-management.component";
import { PendingLeavesComponent } from '../components/leave/pending-leaves/pending-leaves.component';
import { ProcessedLeavesComponent } from '../components/leave/processed-leaves/processed-leaves.component';
import { ExpiredLeavesComponent } from '../components/leave/expired-leaves/expired-leaves.component';
import { LeaveComponent } from "../components/leave/leave.component";
import { InvoiceComponent } from "../components/generate-invoice/generate-invoice.component";
import { InvoiceDetailComponent } from "../components/generate-invoice/invoice-detail/invoice-detail.component";
import { SaveAndPostInvoiceComponent } from "../components/generate-invoice/save-and-post-invoice/save-and-post-invoice.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NewJobComponent } from "../dashboards/providers-dashboard/appointment/new-job/new-job.component";
import { SvgIconComponent } from "../components/svg-icon/svg-icon.component";
import { DashboardCardsComponent } from "../components/dashboard-cards-component/dashboard-cards-component.component";
import { OrganizationDashboardComponent } from "../dashboards/organization-dashboard/organization-dashboard.component";
import { DashboardTodaysJobComponent } from "../dashboards/providers-dashboard/dashboard-todays-job/dashboard-todays-job.component";
import { AddLocationComponent } from "../dashboards/providers-dashboard/patient/add-location/add-location.component";
import { NewJobCardComponent } from "../components/new-job-card/new-job-card.component";
import { NotifiedListComponent } from "../components/new-job-card/notified-list/notified-list.component";
import { NotifyJobComponent } from "../components/new-job-card/notify-job/notify-job.component";
import { ClockinClockoutJobCardComponent } from "../components/new-job-card/clockin-clockout-job-card/clockin-clockout-job-card.component";
import { AssignJobComponent } from "../components/new-job-card/assign-job/assign-job.component";
import { NewJobCardService } from "../components/new-job-card/new-job-card.service";
import { AutoCloseDirective } from "./services/directives/autoClose.directive";
import { CheckOutMissedListComponent } from "../components/check-out-missed-list/check-out-missed-list.component";
import { PhoneMaskDirective } from "./services/directives/phoneMask.directive";
import { PopoverDirective } from "./services/directives/popover.directive";
import { PopoverComponent } from "../components/popover/popover.component";
import { ProfileAvatarWithName } from"../components/profile-avatar-with-name/profile-avatar-with-name.component";
import { NumberOnlyDirective } from "./services/directives/numberOnly.directive";
import { PositiveNumberOnlyDirective } from "./services/directives/positiveNumberOnly.directive";
import { InputNumberFloatOnly } from "./services/directives/inputFloatNumberOnly.directive";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TrackingLogComponent } from "../dashboards/technician-dashboard/tracking-log/tracking-log.component";
import { AdminRequestedNewJobListComponent } from "../dashboards/providers-dashboard/admin-requested-new-job-list/admin-requested-new-job-list.component";
import { CancelJobsComponent } from "../dashboards/providers-dashboard/cancel-jobs/cancel-jobs.component";
import { AcceptRequestedJobComponent } from "../components/accept-requested-job/accept-requested-job.component";
import { StaffJobCardComponent } from "../components/staff-job-card/staff-job-card.component";
import { ScheduledStaffCardComponent } from "../components/scheduled-staff-card/scheduled-staff-card.component";
import { ViewJobDetailsComponent } from "../dashboards/providers-dashboard/appointment/view-job-details/view-job-details.component";
import { WvcTimeShortingPipe } from './services/pipe/wvc-time-shorting.pipe';
import { CustShortJobTypeDtsComponent } from "../components/cust-short-job-type-dts/cust-short-job-type-dts.component";
import { CommonCardComponent } from "../components/common-card/common-card.component";
import { ShiftWithJobTypeComponent } from "../components/shift-with-job-type/shift-with-job-type.component";
import {MatSliderModule} from '@angular/material/slider';
import { ShortCutsComponent } from "../components/short-cuts/short-cuts.component";
import { CheckOutMissedService } from "../components/check-out-missed-list/check-out-missed-list.service";
import { RequestNewJobService } from "../dashboards/customer-dashboard/cust-request-new-job/request-new-job.service";
import { TimeInputSelect } from "../common/time-input-dropdown/time-input-dropdown.component";
import { BreakFormComponent } from "../common/break-form/break-form.component";
import { UpdateClockInOutTimeComponent } from "../components/update-clock-in-out-time/update-clock-in-out-time.component";
import { StaffListSelectComponent } from "../components/staff-list-select/staff-list-select.component";
import { EmailTemplateComponent } from "../components/email-template/email-template.component";
import { BulkEmailComponent } from "../components/email-template/bulk-email/bulk-email.component";
import { AddEmailTemplateComponent } from "../components/email-template/add-email-template/add-email-template.component";
import { DirectEmailSendComponent } from "../components/email-template/direct-email-send/direct-email-send.component";
import { EmailConfigurationSetting } from "../components/email-configuration-setting/email-configuration-setting.component";
import { QuillModule } from 'ngx-quill';
import { RosterPrintComponent } from "../components/roster-print/roster-print.component";
import { QRCodeModule } from "angularx-qrcode";
import { ChatLogComponent, lib_pipe_niceDateFormat } from 'src/app/chat/e-chat-log/e-chat-log.component';
import { CommonAddViewDocumentsComponent } from '../components/common-add-view-documents/common-add-view-documents.component';
import { AddNewDocumentComponent } from '../components/common-add-view-documents/add-new-document/add-new-document.component';
import { WorkerProfSkillsComponent } from "../dashboards/providers-dashboard/technician/worker-prof-skills/worker-prof-skills.component";
import { RepeatJobComponent } from '../components/repeat-job/repeat-job.component';
import { CustomerLimitsViewDirective } from "./services/directives/customer-view-limit.directive";
import { MobileNumberFormatorePipe } from "./services/pipe/mobile-formator.pipe";
import { WidgetModule } from "./widget/widget.module";
import { PipesModule } from "./services/pipe/pipes.module";

@NgModule({
  declarations: [
    CountryCodeComponent,
    RejectAppointmentComponent,
    HighlightDirective,
    ProcedureDetailComponent,
    NotificationComponent,
    UnauthorizedComponent,
    SiteMaintenanceComponent,
    SearchPipeFilter,
   // NoCommaPipe,
    CapitalizePipe,
    StaffViewComponent,
    JobViewComponent,
    UserRegistrationComponent,
    CardViewComponentComponent,
    ActivityLogComponent,
    DistributionListComponent,
    FailedStaffListPopup,
    AddDistributionComponent,
    AddTeamSiteComponent,
    AddDistributionInStaff,
    AssignedDistributionListForStaff,
    AssignedDistributionListPopup,
    ClockedOutPopUp,
    CurrentDateTimeComponent,
    CommonDataListViewComponent,
    AddUpdateNoteComponent,
    NotesListComponent,
    ThreadedNoteListComponent,
    GroupedNoteListComponent,
    TeamManagementComponent,
    WeeklyCalendarComponent,  
    LeaveComponent,
    InvoiceComponent,
    PendingLeavesComponent,
    ProcessedLeavesComponent,
    ExpiredLeavesComponent,
    WeeklyCalendarComponent,
    InvoiceDetailComponent,
    SaveAndPostInvoiceComponent,
    NewJobComponent,
    SvgIconComponent,
    DashboardCardsComponent,
    OrganizationDashboardComponent,
    DashboardTodaysJobComponent,
    AddLocationComponent,
    NewJobCardComponent,
    NotifiedListComponent,
    NotifyJobComponent,
    ClockinClockoutJobCardComponent,
    AssignJobComponent,
    StaffJobCardComponent,
    AutoCloseDirective,
    CheckOutMissedListComponent,
    PhoneMaskDirective,
    PopoverDirective,
    PopoverComponent,
    ProfileAvatarWithName,
    NumberOnlyDirective,
    PositiveNumberOnlyDirective,
    InputNumberFloatOnly,
    TrackingLogComponent,
    AdminRequestedNewJobListComponent,
    CancelJobsComponent,
    AcceptRequestedJobComponent,
    ScheduledStaffCardComponent,
    ViewJobDetailsComponent,
    WvcTimeShortingPipe,
    CustShortJobTypeDtsComponent,
    CommonCardComponent,
    ShiftWithJobTypeComponent,
    ShortCutsComponent,
    TimeInputSelect,
    BreakFormComponent,
    UpdateClockInOutTimeComponent,
    StaffListSelectComponent,
    EmailTemplateComponent,
    BulkEmailComponent,
    AddEmailTemplateComponent,
    DirectEmailSendComponent,
    EmailConfigurationSetting,
    RosterPrintComponent,
    ChatLogComponent,
   lib_pipe_niceDateFormat,
   CommonAddViewDocumentsComponent,
   AddNewDocumentComponent,
   WorkerProfSkillsComponent,
   RepeatJobComponent,
   CustomerLimitsViewDirective,
   MobileNumberFormatorePipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatStepperModule,
    AngularWebStorageModule,
    HttpClientModule,
    RouterModule,
    GooglePlaceModule,
    InfiniteScrollModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    ScrollingModule,
    MatButtonToggleModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCn4jxvf3gmMxb7sBUZBRozcRHo6gXycVk'
    }),
    // ResizableModule,
    // FullCalendarModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    NgxDaterangepickerMd.forRoot(),
    MatButtonModule,
     MatProgressSpinnerModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule,
    AngularMultiSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useClass: CustomTranslateLoader,
        useFactory:HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    Ng5SliderModule,
    ExportAsModule,
    NgxMaterialTimepickerModule,
    PopoverModule.forRoot(),
    MatSliderModule,
    QuillModule.forRoot(),
    QRCodeModule,
    WidgetModule,
    PipesModule
  ],
  exports: [
    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatDialogModule,
    MatStepperModule,
    AngularWebStorageModule,
    HttpClientModule,
    RouterModule,
    GooglePlaceModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    AgmCoreModule,
    // ResizableModule,
    // FullCalendarModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    InfiniteScrollModule,
    NgxDaterangepickerMd,
    MatButtonModule,
     NgxMatSelectSearchModule,
    CountryCodeComponent,
    AngularMultiSelectModule,
    RejectAppointmentComponent,
    TranslateModule,
    HighlightDirective,
    ProcedureDetailComponent,
    NotificationComponent,
    UnauthorizedComponent,
    SiteMaintenanceComponent,
    Ng5SliderModule,
    ExportAsModule,
    SearchPipeFilter,
   // NoCommaPipe,
    CapitalizePipe,
    StaffViewComponent,
    JobViewComponent, 
    UserRegistrationComponent,
    NgxMaterialTimepickerModule,
    CardViewComponentComponent,
    ActivityLogComponent,
    DistributionListComponent,
    FailedStaffListPopup,
    AddTeamSiteComponent,
    AddDistributionInStaff,
    AssignedDistributionListForStaff,
    AssignedDistributionListPopup,
    ClockedOutPopUp,
    CurrentDateTimeComponent,
    CommonDataListViewComponent,
    AddUpdateNoteComponent,
    NotesListComponent,
    ThreadedNoteListComponent,
    GroupedNoteListComponent,
    TeamManagementComponent,
    AddDistributionComponent,
    LeaveComponent,
    InvoiceComponent,
    PendingLeavesComponent,
    ProcessedLeavesComponent,
    ExpiredLeavesComponent,
    WeeklyCalendarComponent,
    NewJobComponent,
    SvgIconComponent,
    DashboardCardsComponent,
    OrganizationDashboardComponent,
    DashboardTodaysJobComponent,
    AddLocationComponent,
    NewJobCardComponent,
    NotifiedListComponent,
    NotifyJobComponent,
    ClockinClockoutJobCardComponent,
    AssignJobComponent,
    StaffJobCardComponent,
    AutoCloseDirective,
    CheckOutMissedListComponent,
    PhoneMaskDirective,
    PopoverDirective,
    PopoverComponent,
    ProfileAvatarWithName,
    NumberOnlyDirective,
    PositiveNumberOnlyDirective,
    InputNumberFloatOnly,
    MatProgressBarModule,
    TrackingLogComponent,
    AdminRequestedNewJobListComponent,
    CancelJobsComponent,
    AcceptRequestedJobComponent,
    ScheduledStaffCardComponent,
    ViewJobDetailsComponent,
    CustShortJobTypeDtsComponent,
    CommonCardComponent,
    ShiftWithJobTypeComponent,
    MatSliderModule,
    ShortCutsComponent,
    TimeInputSelect,
    BreakFormComponent,
    SaveAndPostInvoiceComponent,
    UpdateClockInOutTimeComponent,
    StaffListSelectComponent,
    EmailTemplateComponent,
    BulkEmailComponent,
    AddEmailTemplateComponent,
    DirectEmailSendComponent,
    EmailConfigurationSetting,
    QuillModule,
    RosterPrintComponent,
    QRCodeModule,
    ChatLogComponent,
    lib_pipe_niceDateFormat,
    CommonAddViewDocumentsComponent,
    AddNewDocumentComponent,
    WorkerProfSkillsComponent,
    RepeatJobComponent,
    CustomerLimitsViewDirective,
    MobileNumberFormatorePipe,
    PipesModule
  ],
  entryComponents: [
    RejectAppointmentComponent,
    ProcedureDetailComponent
  ],
  providers:[
    NewJobCardService,
    CheckOutMissedService,
     RequestNewJobService,
    MatDialog,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },

  ]
})

export class SharedModule { }



// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  // http.get('http://superadmin.gigflex.com/assets/homehelthcare/')
  //const url =  this
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}