import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { AuthGuardService } from '../shared/auth-guard.service';

const routes: Routes = [
  { path: '',component:Layout1Component, loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule) },  
  { path: 'dashboard', component:Layout2Component,
  loadChildren: () => import('../dashboards/providers-dashboard/providers-dashboard.module').then(m => m.ProvidersDashboardModule), canActivate: [AuthGuardService] },
  { path: 'dashboard-technician', component:Layout2Component,
  loadChildren: () => import('../dashboards/technician-dashboard/technician-dashboard.module').then(m => m.TechnicianDashboardModule), canActivate: [AuthGuardService] },
 
  { path: 'dashboard-customer', component:Layout2Component,
  loadChildren: () => import('../dashboards/customer-dashboard/customer-dashboard-module/customer-dashboard.module').then(m => m.CustomerDashboardModule), canActivate: [AuthGuardService] },

  /* { path: 'dashboard-customer', component:Layout2Component,
  loadChildren: () => import('../dashboards/customer-dashboard/customer-dashboard-module/customer-dashboard.module').then(m => m.CustomerDashboardModule), canActivate: [AuthGuardService] },
  */
 
  { path: 'EmailAccount',
    loadChildren: () => import('../oauth/email-oauth.module').then(m => m.EmailOauthModule)
  },

  { path: '**',component:Layout1Component, loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
