import { NgModule } from '@angular/core';
//import { CommonModule } from '@angular/common';
import { RoundPipe } from './round.pipe';
import { CommonModule } from '@angular/common';
import { NoCommaPipe } from 'src/app/common/pipe-filter/pipe-filter.component';



@NgModule({
  declarations: [
    RoundPipe,
    NoCommaPipe
  ],
  imports: [CommonModule],
  exports:[
    RoundPipe,
    NoCommaPipe
  ]
})
export class PipesModule { }
