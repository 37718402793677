import { Component, OnInit, Input} from '@angular/core';
import { TechnicianService } from '../../dashboards/providers-dashboard/technician/technician.service';
import { CommonService } from 'src/app/shared/common.service';
import { ICustomerViewLimitations } from 'src/app/shared/interface/ICustomerViewLimits';

@Component({
  selector: 'profile-avatar-with-name',
  templateUrl: './profile-avatar-with-name.component.html',
  styleUrls: ['./profile-avatar-with-name.component.scss']
})
export class ProfileAvatarWithName implements OnInit {
  fileUrl: any;
  appPopoverData: any;
  @Input() lableTxt: any;
  @Input() userLogo: any;
  @Input() userName: any;
  @Input() userDetail: any;
  @Input() isProfileButtonEnable: boolean = false;
  @Input() isChatButtonEnable: boolean = false;
  @Input() popoverShowFor: any;
  @Input() isRowNameWithLogo: boolean = false;

  @Input() loggedIn_userType : string = '' ;

  customerViewLimits : ICustomerViewLimitations

  constructor(
    public technicianService: TechnicianService,
    private commonService : CommonService) {}

  ngOnInit() {
    // console.log('userDetail: ', this.userDetail, this.isRowNameWithLogo);
    // console.log('isProfileButtonEnable: ', this.isProfileButtonEnable);
    // console.log('isChatButtonEnable: ', this.isChatButtonEnable);
    // console.log('popoverShowFor: ', this.popoverShowFor);
    this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/';
    let appPopoverData = {
      userLogo: this.userDetail?.staffLogo || this.userDetail?.workerLogo || this.userDetail?.userLogo || this.userDetail?.logo || this.userDetail?.photo || this.userDetail?.customerLogo,
      userName: this.userDetail?.staffName || this.userDetail?.workerName || this.userDetail?.userName || this.userDetail?.name || this.userDetail?.customerName,
      userCode: this.userDetail?.staffCode || this.userDetail?.workerCode || this.userDetail?.userCode || this.userDetail?.code || this.userDetail?.customerCode,
      userDetail: this.userDetail,
      lableTxt: this.lableTxt,
      isProfileButtonEnable: this.isProfileButtonEnable === true ? true : this.isProfileButtonEnable === undefined ? false : false,
      isChatButtonEnable: this.isChatButtonEnable === true ? true : this.isChatButtonEnable === undefined ? false : false,
      popoverShowFor: this.popoverShowFor,
      isRowNameWithLogo: this.isRowNameWithLogo
    };
    this.appPopoverData = appPopoverData;
    // console.log('appPopoverData: ', appPopoverData); 
  this.subscribeToCustomerViewLimit();

  }

  subscribeToCustomerViewLimit()
  {
    this.commonService.getCustomerViewLimits().subscribe((res:ICustomerViewLimitations)=>{

      if(res)
      {
        this.customerViewLimits = res ;
      }

    });
  }
  
}

