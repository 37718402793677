import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonService } from '../../common.service';
import { ICustomerViewLimitations } from '../../interface/ICustomerViewLimits';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, takeUntil } from 'rxjs';
 
@Directive({
  selector: '[canCustomerView]'
  
})
export class CustomerLimitsViewDirective implements OnInit, OnDestroy {
  @Input() canCustomerView: string;  
 
   
  private customerViewLimits : ICustomerViewLimitations;
  private userType :string ;
  private $destroyer = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private commonService : CommonService,
    private sessionService : SessionStorageService
  ) {
     this.userType = this.sessionService.get('user_type');
   }

    ngOnDestroy(): void {
        this.$destroyer.next('');
        this.$destroyer.complete();
    }

    ngOnInit(): void {
        // console.log("directive oniniy");
        if(this.userType === 'Customer')
        {
            this.subscribeToCustomerViewLimit();
        }

        this.updateView();
    }

//   ngOnChanges(changes: SimpleChanges) {
//     if (changes['canCustomerView']) {
//       this.updateView();
//     }
//   }

  private updateView() {

    if(this.userType !== 'Customer')
    {
      //  create the view from the template
        this.viewContainer.createEmbeddedView(this.templateRef);
        return ;
    }

    this.viewContainer.clear(); // Clear the current view

    //let canView = this.haveViewAccess();

    
    if (this.haveViewAccess()) {
        // If true, create the view from the template
        this.viewContainer.createEmbeddedView(this.templateRef);
      } 

    // if (this.canCustomerView) {
    //   // If true, create the view from the template
    //   this.viewContainer.createEmbeddedView(this.templateRef);
    // } 
    // else if (this.canCustomerViewElse) {
    //   // If false and else template is provided, create the else view
    //   this.viewContainer.createEmbeddedView(this.canCustomerViewElse);
    // }
  }


  subscribeToCustomerViewLimit()
  {
    this.commonService.getCustomerViewLimits().pipe(takeUntil(this.$destroyer)).subscribe((res:ICustomerViewLimitations)=>{

      if(res)
      {
        this.customerViewLimits = res ;
        this.updateView();
      }

    });
  }

  haveViewAccess() : boolean 
  {
    let haveAccess : boolean = false ;
    switch(this.canCustomerView)
    {
        case 'name' :
       haveAccess = this.customerViewLimits?.can_view_staff_name
        break;
        
        case 'email' :
            haveAccess = this.customerViewLimits?.can_view_staff_email
             break;

             case 'mobile' :
                haveAccess = this.customerViewLimits?.can_view_staff_mobile
                 break;

                 case 'profile_pic' :
                    haveAccess = this.customerViewLimits?.can_view_staff_profile_pic
                     break;

                     default:
                        haveAccess ;
    }

     return haveAccess ;

  }


}