import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};


@Component({
  selector: 'repeat-job',
  templateUrl: './repeat-job.component.html',
  styleUrls: ['./repeat-job.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class RepeatJobComponent implements OnInit {

  repeatForm: FormGroup;
  repeatEvery = []
  every = [
    {text: 'Day', value: 'day'},
    {text: 'Week', value: 'week'},
    {text: 'Month', value: 'month'},
  ]
  submitted = true;
  week = false;
  month = false;
  day;
  weekday;
  days = [];
  today = new Date();
  weekdaynumber;


  constructor(public dialogRef: MatDialogRef<RepeatJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    //console.log(this.data);

    for (let i = 1; i <= 20; i++) {
      this.repeatEvery.push({ text: `${i}`, value: i });
    }
    
    this.repeatForm = new FormGroup({
      'startDate': new FormControl(null, Validators.required),
      'repeatnum': new FormControl(null, Validators.required),
      'repeatevery': new FormControl(null, Validators.required),
      'endDate': new FormControl(null, Validators.required),
      'days': new FormControl(null),
      'isDateOfMonth': new FormControl(null),
    })
    if(this.data && this.data.startDate != null && this.data.dataDetails){
      //////console.log('in');
      this.repeatForm.controls['startDate'].setValue(moment(this.data.startDate));
      this.repeatForm.controls['repeatnum'].setValue(this.data.dataDetails.repeatValue);
      this.repeatForm.controls['repeatevery'].setValue(this.data.dataDetails.repeatType);
      //this.repeatForm.controls['days'].setValue(this.data.dataDetails.daysList);
      this.repeatForm.controls['isDateOfMonth'].setValue(this.data.dataDetails.isDateOfMonth);
      this.repeatForm.controls['endDate'].setValue(moment(this.data.endDate));
      if(this.data.dataDetails.repeatType === 'week'){
        this.week =true;
        this.day =false;
        this.month =false;

      }

      if(this.data.dataDetails.repeatType === 'day'){
        this.day =true;
        this.week =false;
        this.month =false;
      }


      if(this.data.dataDetails.repeatType === 'month'){
        this.month =true;
        this.day =false;
        this.week =false;
      }
    // const days = this.data.dataDetails.daysList.split(',');
    // if(days.length > 0){
    //   days.forEach(day => {
    //       this.days.push(+day);
    //   });
    // }
      // this.startDate(moment(this.data.startDate));
      this.days = this.data.dataDetails.daysList;
      this.repeatForm.controls['days'].setValue(this.days);
      this.startDate(this.data.startDate);
    }else if(this.data && this.data.startDate != null){
      this.repeatForm.controls['startDate'].setValue(moment(this.data.startDate));
      this.startDate(this.data.startDate);
    }
  }
/* Name:Vivek Chauhan
       use: oncahnge detail for week and month */
  onChange(event){
    this.repeatEvery = [];
    //console.log(event);
    if(event.value == 'day'){
      this.month = true;
      for (let i = 1; i <= 30; i++) {
        this.repeatEvery.push({ text: `${i}`, value: i });
      }
    }
    if(event.value == 'week'){
      for (let i = 1; i <= 20; i++) {
        this.repeatEvery.push({ text: `${i}`, value: i });
      }
      this.week = true;
    } else {
      this.week = false;      
    }    
    if(event.value == 'month'){
      this.month = true;
      for (let i = 1; i <= 12; i++) {
        this.repeatEvery.push({ text: `${i}`, value: i });
      }
    } else {
      this.month = false;
    }
  }
/* Name:Vivek Chauhan
       use:set date to week */
  startDate(event){
    //console.log(event);
    // ////console.log(moment(event.value));
    //////console.log(moment(event.value).format('w'));
    // this.weekOfMonth(moment(event.value));
    const date = moment(event);

    let weekInYearIndex = date.week();
    if (date.year() !== date.weekYear()) {
      weekInYearIndex = date.clone().subtract(1,'week').week() + 1;
    }
    const weekIndex = weekInYearIndex - moment(date).startOf('month').week() + 1;

    if(weekIndex == 1){
      this.weekdaynumber = 'first';
    } else if(weekIndex == 2){
      this.weekdaynumber = 'second';
    } else if(weekIndex == 3){
      this.weekdaynumber = 'third';
    } else if(weekIndex == 4){
      this.weekdaynumber = 'fourth';
    } else if(weekIndex == 5){
      this.weekdaynumber = 'fifth';
    } else if(weekIndex == 6){
      this.weekdaynumber = 'sixth';
    }

    ////console.log(event);

    if(event){
      this.day = moment(event).date();
      this.weekday = moment(event).format('dddd');
      ////console.log(this.day);
      ////console.log(this.weekday);
    }
  }
/* Name:Vivek Chauhan
       use: select day in calneder */
  selectedDay(event, day){
    //////console.log(event);
    if(event.checked == true){
      this.days.push(day);
    } else {
      const index = this.days.indexOf(day);
      if (index !== -1) {
        this.days.splice(index, 1);
      }
    }
    //////console.log(this.days);
    this.repeatForm.controls['days'].setValue(this.days);
  }
/* Name:Vivek Chauhan
       use: cancel popup */
  cancel(){
    this.dialogRef.close();
  }

/* Name:Vivek Chauhan
       use: close popup after valid*/
  onSubmit(){
    //console.log(this.repeat);
    if(this.repeatForm.valid){
      console.log('RepeatForm_Req_Body: ', this.repeatForm.value);
      this.submitted = true;
      // this.dialogRef.close(this.repeatForm.value);      
    } else {
      this.submitted = false;
    }
  }

}

