import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from '../new-job-card.service';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'notified-list',
  templateUrl: './notified-list.component.html',
  styleUrls: ['./notified-list.component.scss']
})
export class NotifiedListComponent implements OnInit {

  notifyUsersList:any[] = [];
  showSpinner: boolean = false;
  popupTitle: any;
 userType:string ;

  constructor(
    public dialogRef: MatDialogRef<NotifiedListComponent>, 
    public dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public data: any,
      private jobCardService: NewJobCardService,
      public dashboarService: DashboardService,
      private sessionStorage : SessionStorageService,
      private commonService : CommonService
    ) {}


  ngOnInit(): void {
    // console.log('Data: ', this.data);
    if(this.data){
      this.popupTitle = this.data?.status;
      this.getNofifyedStaffList(this.data);
    }

    this.userType = this.sessionStorage.get("user_type");
  }

  getNofifyedStaffList(data: any){
    if(data.appoinmentCode && data.appoinmentProcedureCode && data.date){
    this.showSpinner = true;
    this.jobCardService.getNotifiedUserList(data.appoinmentCode, data.appoinmentProcedureCode, data.date)
      .subscribe((res: any) => {
        this.showSpinner = false;      
        if (res.responsecode == 200) {
          this.notifyUsersList = res.data.map(ele=>{
            return{
              ...ele,
              badgeColor : this.commonService.getbadgeClass(ele.notifyStatus)
            }
          });
        }
        else {
          this.notifyUsersList = [];
        }
      })
    }
  }
 

  reNotifyStaff(data:any)
  {
    this.commonService.progressLoader = true ;
    this.jobCardService.reNotifyStaff(
      this.sessionStorage.get("orgCode"),
      data.appointmentsNotifiedCode
    ).subscribe((res:any)=>{
      this.commonService.progressLoader = false ;
      this.commonService.message = res.message ;
      if(res.responsecode == 200)
      {
       this.commonService.successMessage = true;
       this.getNofifyedStaffList(this.data);
      }
      
      else 
      {
        this.commonService.errorMessage = true;
      }
      

    });
  }


}
